import React, { useState, useEffect } from "react";
import "./WebsitesCare.css";
import { client, urlFor } from "../../../client";
// import {PortableText} from '@portabletext/react'
import { BlockContent, PortableText } from "@sanity/block-content-to-react";
import { BodyText } from "../../../components";

const WebsitesCare = () => {
  const [headData, setheadData] = useState([]);
  const [care, setcare] = useState([]);
  const [doc, setdoc] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `
        *[_type == "websites_heads"]{
          websites_care_head
        }
        `
      )
      .then((data) => setheadData(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    client
      .fetch(
        `
        *[_type == "website_care"]{
          imgUrl,
             
        }
        `
      )
      .then((data) => setcare(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    client
      .fetch(
        `
        *[_type == "website_care"][0]{
          blockContent
        }
        `
      )
      .then((data) => setdoc(data))
      .catch(console.error);
  }, []);
  // console.log(doc)
  // console.log(careTextBlock.text)

  return (
    <div className="websitesCare__container sekcja-padding">
      <div className="websitesCare__img-section">
        {care.map((data, index) => (
          <>
            <img
              src={urlFor(data.imgUrl)}
              alt="opieka"
              key={index + "opieka"}
            />
          </>
        ))}
      </div>
      <div className="websitesCare__text-section">
        <div className="websitesCare__text-section_head">
          {headData.map((data, index) => {
            return (
              <>
                <h4
                  className="section-category"
                  key={
                    index + data.websites_care_head.category_name + "kategoria"
                  }>
                  {" "}
                  {data.websites_care_head.category_name}{" "}
                </h4>
                <h2
                  className="section-title"
                  key={index + data.websites_care_head.title + "tytuł"}
                  style={{ textAlign: "left" }}>
                  {data.websites_care_head.title}
                </h2>
                <h2
                  className="section-subtitle"
                  key={index + data.websites_care_head.sub_title + "subtytuł"}
                  style={{ textAlign: "left" }}>
                  {data.websites_care_head.sub_title}
                </h2>
              </>
            );
          })}
        </div>
        <div className="websitesCare__text-section_paragraph">
          <p className="section-paragraph" style={{ marginTop: "2rem" }}>
            <BodyText doc={doc} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default WebsitesCare;
