import React from "react";
import "./WebsiteOffer.css";
import {
  Benefits,
  Contact,
  DlaczegoMy,
  LandingPagesExamples,
  StopkaSukces,
  SukcesOnlineOffer,
  WebsiteOfferHeader
} from "./containers";
import { MenuWebsiteOffer } from "./components";
import ReactGA from "react-ga4";
import { LandingPagesData } from "../../../libs";

const GA_MEASUREMENT_ID = "G-RCD77HTCSQ";

ReactGA.initialize(GA_MEASUREMENT_ID, { debug: true });

const WebsiteOffer = () => {
  return (
    <div className="websiteoffer__kontener">
      <div className="websiteoffer-menuMobile__kontener">
        <MenuWebsiteOffer />
      </div>
      <WebsiteOfferHeader
        img={LandingPagesData[0].img}
        title={LandingPagesData[0].title}
        sub_title={LandingPagesData[0].sub_title}
        p1={LandingPagesData[0].p1}
        p2={LandingPagesData[0].p2}
        p={LandingPagesData[0].p_color}
      />
      <Benefits />
      <DlaczegoMy />
      <LandingPagesExamples />
      <SukcesOnlineOffer />
      <Contact />
      <StopkaSukces />
    </div>
  );
};

export default WebsiteOffer;
