import React from "react";
import PortableText from "@sanity/block-content-to-react";
import urlBuilder from "@sanity/image-url";
// import doc from "./document.json";
// import "./styles.css";

import BlockContent from "@sanity/block-content-to-react";
import { urlFor } from "../../client";
// import doc from "./document.json";

function BodyText({ doc }) {
  // console.log(doc)

  const serializer = {
    types: {
      mainImage: (props) => (
        <figure>
          <img
            src={urlFor(props.node.asset).width(600).url()}
            alt={props.node.alt}
          />

          <figcaption>{props.node.caption}</figcaption>
        </figure>
      ),
      block: (props) => {
        const style = props.node.style || "normal";

        if (/^h\d/.test(style)) {
          const level = style.replace(/[^\d]/g, "");
          return React.createElement(`h${level}`, {}, props.children);
        }

        return style === "blockquote" ? (
          <blockquote>– {props.children}</blockquote>
        ) : (
          <p>{props.children}</p>
        );
      },
      code: (props) => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      )
    },
    list: (props) =>
      props.type === "bullet" ? (
        <ul>{props.children}</ul>
      ) : (
        <ol>{props.children}</ol>
      ),
    listItem: (props) =>
      props.type === "bullet" ? (
        <li>{props.children}</li>
      ) : (
        <li>{props.children}</li>
      ),
    marks: {
      strong: (props) => <strong>{props.children}</strong>,
      em: (props) => <em>{props.children}</em>,
      code: (props) => <code>{props.children}</code>,
      sup: (props) => <sup>{props.children}</sup>,
      sub: (props) => <sub>{props.children}</sub>
    }
  };

  return (
    <div className="blockContent">
      <BlockContent blocks={doc.blockContent} serializers={serializer} />
      {/* <hr /> */}
      {/* <h2>Raw data</h2> */}
      {/* <pre>{JSON.stringify(doc.blockContent, null, 2)}</pre> */}

      {/* <p>{doc.bodyPortableText.children.text}</p> */}
    </div>
  );
}

export default BodyText;

// KOPIA ZAPASOWA
// const serializer = {
//   types: {
//     mainImage: (props) => (
//       <figure>
//         <img
//           src={urlFor(props.node.asset).width(600).url()}
//           alt={props.node.alt}
//         />

//         <figcaption>{props.node.caption}</figcaption>
//       </figure>
//     ),
//     block: (props) => {
//       const style = props.node.style || "normal";

//       if (/^h\d/.test(style)) {
//         const level = style.replace(/[^\d]/g, "");
//         return React.createElement(`h${level}`, {}, props.children);
//       }

//       return style === "blockquote" ? (
//         <blockquote>– {props.children}</blockquote>
//       ) : (
//         <p>{props.children}</p>
//       );
//     },
//     code: (props) =>
//       console.log("code block", props) || (
//         <pre data-language={props.node.language}>
//           <code>{props.node.code}</code>
//         </pre>
//       )
//   },
//   list: (props) =>
//     console.log("list", props) ||
//     (props.type === "bullet" ? (
//       <ul>{props.children}</ul>
//     ) : (
//       <ol>{props.children}</ol>
//     )),
//   listItem: (props) =>
//     console.log("list", props) ||
//     (props.type === "bullet" ? (
//       <li>{props.children}</li>
//     ) : (
//       <li>{props.children}</li>
//     )),
//   marks: {
//     strong: (props) =>
//       console.log("strong", props) || <strong>{props.children}</strong>,
//     em: (props) => console.log("em", props) || <em>{props.children}</em>,
//     code: (props) =>
//       console.log("code", props) || <code>{props.children}</code>,
//     sup: (props) => console.log("sup", props) || <sup>{props.children}</sup>,
//     sub: (props) => console.log("sub", props) || <sub>{props.children}</sub>
//   }
// };
