import { TbWorldWww } from "react-icons/tb";
import { IoShareSocialOutline } from "react-icons/io5";
import { MdOutlineMonochromePhotos } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { BsTextParagraph } from "react-icons/bs";
import { client } from "../../client";

const offerWWW = [];
const socialMedia = [];
const retouch = [];
const doradztwo = [];

client
  .fetch(`*[_type == "header_offer"][0]`)
  .then((titles) => {
    offerWWW.push(titles.offer_www);
    socialMedia.push(titles.offer_socialMedia);
    retouch.push(titles.offer_retouch);
    doradztwo.push(titles.offer_doradztwo);
  })
  .catch(console.error);

// console.log(offerWWW);

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    icon: <TbWorldWww />,
    title: "Strony WWW",
    opis: offerWWW
  },
  {
    icon: <IoShareSocialOutline />,
    title: "Social Media",
    opis: socialMedia
  },
  {
    icon: <MdOutlineMonochromePhotos />,
    title: "Retusz Zdjęć",
    opis: retouch
  },
  {
    icon: <BsTextParagraph />,
    title: "Tworzenie Treści",
    opis: doradztwo
  }
];
