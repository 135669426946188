import React from "react";
import "./HeaderOffer.css";
import { HeaderOfferList } from "../../../libs";

const HeaderOffer = () => {
  return (
    <div className="headerOffer__container sekcja-padding">
      <div className="headerOffer__container-section">
        {HeaderOfferList.map((data, index) => {
          return (
            <div className="headerOffer__module-container" key={index}>
              <div className="headerOffer__module-container-overlay"></div>
              <div className="headerOffer__module-head_section">
                <p>
                  <span>{data.icon}</span>
                </p>
                <h4>{data.title}</h4>
              </div>
              <div className="headerOffer__module-text_section">
                <p>{data.opis}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HeaderOffer;
