/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState, useEffect } from "react";
import "./Header.css";
import "./sliderAnimations.css";
import { Nav, HeaderContact } from "../../components";
import { HeaderBackground01 } from "../../assets";
import { client } from "../../client";

const Header = ({
  setreadMore,
  setactiveWWW,
  setactiveSocialMedia,
  setactiveRetouch,
  setsuwakIndex,
  setsuwakActive,
  activeWWW,
  activeSocialMedia,
  activeRetouch,
  contactRef,
  topScrollerRef,
  setaboutUsActive
}) => {
  const [contactModule, setcontactModule] = useState(false);
  const [active, setactive] = useState(true);
  const [slideShowData, setslideShowData] = useState([]);

  useEffect(() => {
    client
      .fetch('*[_type == "slide_text"][0]')
      .then((data) => setslideShowData(data))
      .catch(console.error);
  }, []);

  return (
    <div
      className="header__container"
      style={{ backgroundImage: `url(${HeaderBackground01})` }}
      id="header"
      ref={topScrollerRef}>
      <div className="header__container-overlay">
        {contactModule && <HeaderContact setcontactModule={setcontactModule} />}

        <div className="header__sekcja sekcja-padding">
          <Nav
            setreadMore={setreadMore}
            setcontactModule={setcontactModule}
            setactiveWWW={setactiveWWW}
            setactiveSocialMedia={setactiveSocialMedia}
            setactiveRetouch={setactiveRetouch}
            setsuwakIndex={setsuwakIndex}
            setsuwakActive={setsuwakActive}
            contactRef={contactRef}
            setaboutUsActive={setaboutUsActive}
          />

          <div className="header__slideshow-container">
            <div
              className={`slideshow__window-container`}
              id="left_slide_container">
              <div
                className={`slideshow__window-h3_container ${
                  active && "slide-z-gory-na-dol"
                } `}
                id="left_slide_h3">
                <h3>{slideShowData.slide_text_title_h3}</h3>
              </div>
              <div
                className={`slideshow__window-h1_container ${
                  active && "slide-z-lewej-na-prawa"
                }`}
                id="left_slide_h1">
                <h1>
                  {`${slideShowData.slide_text_title_h1} `}
                  <span>
                    {slideShowData.slide_text_title_h1_span
                      ? slideShowData.slide_text_title_h1_span
                      : ""}
                  </span>
                </h1>
              </div>
              <div
                className={`slideshow__window-p_container ${
                  active && "slide-z-prawej-na-lewa"
                }`}
                id="left_slide_p">
                <p>{slideShowData.slide_paragraph}</p>
              </div>
              <div
                className={`slideshow__window-button_container ${
                  active && "slide-z-dolu-na-gore"
                }`}
                id="left_slide_button">
                {/* <button>
                  <span></span>
                  <p>
                    <a href="#">czytaj więcej</a>
                  </p>
                  <span></span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
