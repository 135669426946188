import React from "react";
import "./Retouch.css";
import {
  RetouchBenefits,
  RetouchExamples,
  RetouchOffer
} from "../../containers";

const Retouch = ({
  readMore,
  setreadMore,
  setid,
  setdoc,
  doc,
  setimgFull,
  settitle,
  setlink,
  setimg,
  setimgBefore
}) => {
  return (
    <div className="retouch__container">
      <RetouchBenefits />
      <RetouchExamples
        readMore={readMore}
        setreadMore={setreadMore}
        setid={setid}
        setdoc={setdoc}
        doc={doc}
        settitle={settitle}
        setimgFull={setimgFull}
        setlink={setlink}
        setimg={setimg}
        setimgBefore={setimgBefore}
      />
      <RetouchOffer />
    </div>
  );
};

export default Retouch;
