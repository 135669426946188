import React from "react";
import "./DlaczegoMy.css";

const DlaczegoMy = () => {
  return (
    <div className="dlaczegomy__kontener">
      <div className="dlaczegomy__kontener-zawartosc sekcja-padding">
        <div className="benefits__title">
          <h4 className="section-category">Dlaczego Twoja firma, powinna</h4>
          <h2 className="section-title">współpracować z nami</h2>
        </div>
        <div className="benefits__paragraph">
          <h3>Po pierwsze: zrozumieć Twój biznes</h3>
          <p>
            Nonsensem byłoby robić to samo w każdej branży i z każdym produktem.
            My postępujemy inaczej. Siadamy do rozmów i cierpliwie odkrywamy
            potencjał klienta. Szukamy silnych stron oraz atutów, jakimi możesz
            podbić rynek.
          </p>
          <p>
            Wstępne idee przekuwamy w strategie. Luźne pomysły zmieniamy w
            kreatywne kampanie. Linijki kodu HTML stają się stronami, do których
            wraca się z przyjemnością. Stale stawiamy na skuteczność, czyniąc
            Twój sukces realnym i efektywnym.
          </p>
        </div>
        <div className="benefits__paragraph">
          <h3>Poznać potrzeby, wyznaczyć cele</h3>
          <p>
            Internet daje olbrzymią przestrzeń do promocji Twej firmy. Jeśli
            wciąż są obszary, w których w ogóle nie działasz lub wykorzystujesz
            ich potencjał tylko w niewielkim stopniu, zajrzymy tam wspólnie, by
            się upewnić, ile możemy z tego wycisnąć.
          </p>
          <p>
            Twój cel staje się naszym celem. Zdefiniujmy go razem i razem
            podążajmy do niego. Tak skonstruowana strategia zawsze przynosi
            owoce – Ty musisz je tylko zebrać.
          </p>
        </div>
        <div className="benefits__paragraph">
          <h3>Synergia wiedzy i doświadczenia</h3>
          <p>
            Żeby liczyć się w digitalowym świecie, potrzebujesz nowoczesnej i
            wymownej identyfikacji wizualnej. Kreacji mówiących: to mnie
            potrzebujesz, ja jestem odpowiedzią i rozwiązaniem, którego szukasz.
            Zajmiemy się tym. Nasi graficy są wirtuozami Photoshopa i
            Ilustratora, a ich pomysłowość nie uznaje granic.
          </p>
          <p>
            Niezbędne są także wymowne opisy. Znamy się na tym – dostarczamy
            przekonujące treści przyjazne użytkownikom i botom wyszukiwarek.
            Nasi copywriterzy wiedzą, jak pogodzić obie te kwestie.
          </p>
          <p>
            A gdy chcesz być sprzedawcą w sieci, zdaj się na naszych
            programistów. W ich DNA wpisane są HTML, CSS, JavaScript i React –
            bezbłędnie radzą sobie z wymaganiami użytkowników i wyszukiwarek
            internetowych.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DlaczegoMy;
