import React from "react";
import "./HeaderContact.css";
import { IoClose } from "react-icons/io5";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { ContactData } from "../../../libs";

const HeaderContact = ({ setcontactModule }) => {
  console.log(ContactData);
  return (
    <div className="header__contact-module_container scale-up-hor-right">
      <div className="headerContact__nav-section">
        <p>
          <button onClick={() => setcontactModule(false)}>
            <IoClose />
          </button>
        </p>
      </div>
      <div className="headerContact__paragraph">
        <h3>dane kontaktowe</h3>
        <p>
          <span>
            <BsFillTelephoneFill />
          </span>{" "}
          {ContactData[0].phone}
        </p>
        <p>
          <span>
            <GrMail />
          </span>{" "}
          <a href="mailto:biuro@agencja-marketingowa.pl">
            {ContactData[1].mail}
          </a>
        </p>
      </div>
    </div>
  );
};

export default HeaderContact;
