import React, { useState, useEffect } from "react";
import "./About.css";
import { ImCheckboxChecked } from "react-icons/im";
import { urlFor, client } from "../../client";
import { BodyText } from "../../components";

const About = ({ topScrollerRef }) => {
  const [abouts, setAbouts] = useState([]);
  const [benefits, setBenefits] = useState([]);

  useEffect(() => {
    client
      .fetch(`*[_type == "about"]`)
      .then((data) => {
        setAbouts(data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    client
      .fetch(`*[_type == "benefits"]`)
      .then((data) => {
        setBenefits(data);
      })
      .catch(console.error);
  }, []);

  return (
    <div className="about__container sekcja-padding">
      <div className="about__container_text-section">
        <div className="about__container-text-headSection">
          {abouts.map((data, index) => (
            <>
              <h4
                className="section-category"
                key={index + data.category + "about"}>
                {data.category}
              </h4>
              <h2
                className="section-title"
                key={index + data.title + "about"}
                style={{ textAlign: "left" }}>
                {data.title}
              </h2>
              <h2
                className="section-subtitle"
                key={data.subtitle + "about"}
                style={{ textAlign: "left" }}>
                {data.subtitle}
              </h2>
            </>
          ))}
        </div>
        <div className="about__container-text-paragraph">
          {abouts.map((data, index) => (
            <>
              <p
                className="section-paragraph"
                key={data.text + index + "about-paragraph"}>
                {data.text}
              </p>
              <p
                className="section-paragraph"
                key={data.text + index + "about-paragraph"}>
                <BodyText doc={data} />
              </p>
            </>
          ))}
        </div>
        <div className="about__container-text-paragraph-benefits">
          {benefits.length > 0 &&
            benefits.map((data, index) => {
              return (
                <>
                  <p key={index + data.title + "about-benefits"}>
                    <span>
                      <ImCheckboxChecked />
                    </span>
                    {data.title}
                  </p>
                </>
              );
            })}
        </div>
      </div>
      <div className="about__container_img-section">
        <div className="about__img-section_left">
          <div className="about__img-left-section_top">
            <div className="about__img-overlay"></div>
            {abouts.map((data) => {
              return (
                <>
                  <img
                    src={data.imgUrl_l1 ? urlFor(data.imgUrl_l1) : ""}
                    alt="about01"
                  />
                </>
              );
            })}
          </div>
          <div className="about__img-left-section_bottom">
            <div className="about__img-overlay"></div>
            {abouts.map((data) => {
              return (
                <>
                  <img
                    src={data.imgUrl_l2 ? urlFor(data.imgUrl_l2) : ""}
                    alt="about02"
                  />
                </>
              );
            })}
          </div>
        </div>
        <div className="about__img-section_right">
          <div className="about__img-overlay"></div>
          {abouts.map((data) => {
            return (
              <>
                <img
                  src={data.imgUrl_r ? urlFor(data.imgUrl_r) : ""}
                  alt="about03"
                />
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default About;
