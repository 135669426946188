import React from "react";

import { Route, Routes, NavLink, Link } from "react-router-dom";
import { OfertaWWW, WywiadWWW } from "../../components";
import "./SellingPage.css";

const SellingPage = () => {
  return (
    <>
      <div className="sellingpage__container">
        <div className="sellingpage__headsection">
          <div className="sellingpage__h-section">
            <h1>Pomoce Sprzedażowe</h1>
          </div>
          <div className="sellingpage__headsection-buttons">
            <NavLink to="wywiad_www">
              <button>wywiad www</button>
            </NavLink>
            <NavLink to="wywiad_socialmedia">
              <button>wywiad social media</button>
            </NavLink>
            <NavLink to="wywiad_retusz">
              <button>wywiad retusz zdjęć</button>
            </NavLink>
          </div>
          <div className="sellingpage__headsection-buttons">
            <NavLink to="oferta_www">
              <button>oferta www</button>
            </NavLink>

            <button>oferta social media</button>

            <button>oferta retusz zdjęć</button>
          </div>
        </div>
        <div className="sellingpage__componentsection">
          <Routes>
            <Route path="wywiad_www" element={<WywiadWWW />} />
            <Route path="wywiad_socialmedia" />
            <Route path="wywiad_retusz" />
            <Route path="oferta_www" element={<OfertaWWW />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default SellingPage;
