import React, {useEffect,useState} from 'react'
import './WebsitesOffer.css'
import { client } from '../../../client'
import { OfferCard, OfferSelectPeriod } from '../../../components'



const WebsitesOffer = () => {

  const [headData, setheadData] = useState([]);
  const [activeButton, setactiveButton] = useState(false);
  const [buttonPeriodId, setbuttonPeriodId] = useState(0);
  const [offerList, setofferList] = useState([]);
  const [opcjeList, setopcjeList] = useState([]);

  useEffect(
    ()=> {
      client.fetch(
        `
        *[_type == "websites_heads"]{
          websites_offer_head
        } 
        `
      ).then(
        data=> setheadData(data)
      ).catch(
        console.error
      )
    },[]
  )

  useEffect(
    ()=> {
      client.fetch(
        `
        *[_type == "website_offer"] 
        `
      ).then(
        data=> setofferList(data)
      ).catch(
        console.error
      )
    },[]
  )

//  console.log(offerList)

  return (
    <div className='websitesoffer__container'>
      <div className='websiteoffer__section sekcja-padding'>
       <div className='websiteoffer__head-section'>
       {
         headData.map(
          (data,index) => {  
            return (   
         <>
     <h4 className='section-category' key={index+data.websites_offer_head.category_name+"kategoria"} > {data.websites_offer_head.category_name} </h4>
     <h2 className='section-title' key={index+data.websites_offer_head.title+"tytuł"}>{data.websites_offer_head.title}</h2>
     <h2 className='section-subtitle'key={index+data.websites_offer_head.sub_title+"subtytuł"}>{data.websites_offer_head.sub_title}</h2>
         </>
         )
 
          }
       )
      }

       </div>
       <div className='websiteoffer__OfferSelectPeriod'>
       <OfferSelectPeriod
       key={'websiteOffer-slectperiod'}
       activeButton={activeButton}
       setactiveButton={setactiveButton}
       setbuttonPeriodId={setbuttonPeriodId}
       buttonPeriodId={buttonPeriodId}
       />

       </div>
       <div className='websiteoffer__offerCard-container'>
      {
        offerList.map(
          (data,index)=> {
  
          return (
            <OfferCard
            key={"offerCard_websites"+index}
            buttonPeriodId={buttonPeriodId}
            title={data.title}
            price_year={data.price_year}
            price_month={data.price_month}
            opis={
              data.opis.map(
                opis=>opis.children.map(
                  text=>text.text
                )
            )
            }
            opcja1={data.opcje.opcja1}
            opcja2={data.opcje.opcja2}
            opcja3={data.opcje.opcja3}
            opcja4={data.opcje.opcja4}
            opcja5={data.opcje.opcja5}
            opcja6={data.opcje.opcja6}
            czas_realizacji={data.czas_realizacji}
            
            />
          )
        }
        )
      }

       </div>
      </div>
      </div>
  )
}

export default WebsitesOffer