import React from "react";
import "./WebsiteOfferHeader.css";
import { HeaderLandingPage01 } from "../../../../../../assets";
import { MenuWebsiteOffer } from "../../../components";

const WebsiteOfferHeader = ({ img, title, sub_title, p1, p2, p_color }) => {
  return (
    <div
      className="websiteofferheader__kontener"
      style={{ backgroundImage: `url(${img})` }}>
      <div className="websiteofferheader__kontener-zawartosc sekcja-padding ">
        <div className="websiteofferheader__menu-kontener">
          <MenuWebsiteOffer />
        </div>
        <div className="websiteofferheader__slideText">
          <h1>{title}</h1>
          <h3>{sub_title}</h3>
          <p style={{ color: `${p_color}` }}>{p1}</p>
          <p>{p2}</p>
          <div className="websiteofferheader__slideText-button"></div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteOfferHeader;
