import React from "react";
import "./ExmapleList.css";
import ExampleCard from "../ExampleCard/ExampleCard";
import { no_img } from "../../../assets";

const ExmapleList = ({
  exmapleData,
  setreadMore,
  readMore,
  setid,
  doc,
  setdoc,
  imgUrl_full,
  title,
  settitle,
  setimgFull,
  setlink,
  setimg,
  setimgBefore
}) => {
  //  setdoc(exmapleData[0])

  return (
    <div className="exmapleList__container">
      {exmapleData.map((data, index) => {
        // console.log(data.imgUrl_before);
        return (
          <ExampleCard
            key={data._id}
            id={data._id}
            title={data.title}
            imgUrl_before={data.imgUrl_before}
            imgUrl={data.imgUrl}
            imgUrl_full={data.imgUrl_full}
            readMore={readMore}
            setreadMore={setreadMore}
            setid={setid}
            doc={data}
            setdoc={setdoc}
            settitle={settitle}
            setimgFull={setimgFull}
            link={data.href ? data.href : ""}
            setlink={setlink}
            setimg={setimg}
            setimgBefore={setimgBefore}
          />
        );
      })}
    </div>
  );
};

export default ExmapleList;
