import React, { useState, useRef, useEffect } from "react";
import "./App.css";

import { useInView } from "react-hook-inview";
import {
  HeaderOffer,
  SuwakiDzialow,
  ExmapleCardMore,
  TopScroller,
  CookieBanner,
  PoliciesBanner,
  AboutUs,
  WywiadWWW
} from "./components";
import {
  Header,
  About,
  AboutSkillset,
  Retouch,
  SocialMedia,
  Webistes,
  Contact,
  Footer
} from "./containers";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { BsJustify } from "react-icons/bs";
import posthog from "posthog-js";
import { client } from "./client";
import { TbLayoutGridRemove } from "react-icons/tb";



function App() {
  //LOGO

  // const divRef = useRef();

  // O NAS
  const [aboutUsActive, setaboutUsActive] = useState(false);

  // Polityka Prywatności

  const [privatePoliciesShow, setprivatePoliciesShow] = useState(false);

  // Ikona do scrollowania
  const [topScrollerActive, settopScrollerActive] = useState(true);
  const [topScrollerRef, isTopScrollerVisible] = useInView({
    threshold: 0.25
  });

  const [contactRef, isContactVisible] = useInView({
    threshold: 0.25
  });

  // ExampleCardMore
  const [readMore, setreadMore] = useState(false);
  const [id, setid] = useState("Standard");
  const [doc, setdoc] = useState(["standardowy tekst"]);
  const [title, settitle] = useState("");
  const [imgFull, setimgFull] = useState();
  const [link, setlink] = useState();
  const [img, setimg] = useState();
  const [imgBefore, setimgBefore] = useState();

  // Aktywacja suwaków
  const [suwakActive, setsuwakActive] = useState(false);
  const [suwakIndex, setsuwakIndex] = useState(null);

  const [activeWWW, setactiveWWW] = useState(false);
  const [activeSocialMedia, setactiveSocialMedia] = useState(false);
  const [activeRetouch, setactiveRetouch] = useState(false);

  useEffect(
    () => {
      isTopScrollerVisible
        ? settopScrollerActive(false)
        : settopScrollerActive(true);
    },
    [isTopScrollerVisible]
  );

  return (
    <>

      <div className="App">
  
      {aboutUsActive && <AboutUs setaboutUsActive={setaboutUsActive} />}

      {privatePoliciesShow &&
        <PoliciesBanner setprivatePoliciesShow={setprivatePoliciesShow} />}

      {posthog.has_opted_in_capturing() || posthog.has_opted_out_capturing()
        ? null
        : <CookieBanner setprivatePoliciesShow={setprivatePoliciesShow} />}

      {/* <CookieConsent
        disableStyles={false}
        containerClasses="cookie__container"
        contentClasses="cookie__container-content"
        location="bottom"
        buttonText="ROZUMIEM"
        cookieName="myAwesomeCookieName2"
        style={{
          background: "var(--bs-gray-500)",
          width: "50%",
          margin: "0 25% 2rem 25%",
          padding: ".5rem",
          zIndex: "999999999999999999"
        }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px", top: "100%" }}
        expires={150}>
        Poniższa Polityka Prywatności określa zasady zapisywania i uzyskiwania
        dostępu do danych na Urządzeniach Użytkowników korzystających z Serwisu
        do celów świadczenia usług drogą elektroniczną przez Administratora oraz
        zasady gromadzenia i przetwarzania danych osobowych Użytkowników, które
        zostały podane przez nich osobiście i dobrowolnie za pośrednictwem
        narzędzi dostępnych w Serwisie. Poniższa Polityka Prywatności jest
        integralną częścią Regulaminu Serwisu, który określa zasady, prawa i
        obowiązki Użytkowników korzystających z Serwisu.{" "}
      </CookieConsent> */}
      <TopScroller
        topScrollerActive={topScrollerActive}
        isTopScrollerVisible={isTopScrollerVisible}
      />

      {readMore &&
        <ExmapleCardMore
          setreadMore={setreadMore}
          id={id}
          doc={doc}
          key={id}
          title={title}
          imgFull={imgFull}
          link={link}
          img={img}
          imgBefore={imgBefore}
          />
        
         }

      <Header
        topScrollerRef={topScrollerRef}
        setreadMore={setreadMore}
        setactiveWWW={setactiveWWW}
        setactiveSocialMedia={setactiveSocialMedia}
        setactiveRetouch={setactiveRetouch}
        setsuwakIndex={setsuwakIndex}
        activeWWW={activeWWW}
        activeSocialMedia={activeSocialMedia}
        activeRetouch={activeRetouch}
        setaboutUsActive={setaboutUsActive}
      />
      <HeaderOffer />
      {/* <div className="refDiv" ref={divRef}> */}
      <About />
      <AboutSkillset />

      <SuwakiDzialow
        ref={topScrollerRef}
        setsuwakIndex={setsuwakIndex}
        suwakIndex={suwakIndex}
        suwakActive={suwakActive}
        activeWWW={activeWWW}
        activeSocialMedia={activeSocialMedia}
        activeRetouch={activeRetouch}
        setactiveWWW={setactiveWWW}
        setactiveSocialMedia={setactiveSocialMedia}
        setactiveRetouch={setactiveRetouch}>
        <Webistes
          readMore={readMore}
          setreadMore={setreadMore}
          setid={setid}
          doc={doc}
          setdoc={setdoc}
          settitle={settitle}
          setimgFull={setimgFull}
          setlink={setlink}
          setimg={setimg}
            ref={topScrollerRef}
            setimgBefore={setimgBefore}
        />
        <SocialMedia
          readMore={readMore}
          setreadMore={setreadMore}
          setid={setid}
          doc={doc}
          setdoc={setdoc}
          settitle={settitle}
          setimgFull={setimgFull}
          setlink={setlink}
          setimg={setimg}
            ref={topScrollerRef}
            setimgBefore={setimgBefore}
        />
        <Retouch
          readMore={readMore}
          setreadMore={setreadMore}
          setid={setid}
          doc={doc}
          setdoc={setdoc}
          settitle={settitle}
          setimgFull={setimgFull}
          setlink={setlink}
          setimg={setimg}
            ref={topScrollerRef}
            setimgBefore={setimgBefore}

        />
      </SuwakiDzialow>

      <Contact contactRef={contactRef} isContactVisible={isContactVisible} />
        <Footer />
        
      </div>

    </>
  // </div>
  );
}

export default App;

