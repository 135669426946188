import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import posthog from "posthog-js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SellingPage, WywiadWWW } from "./components";
import ReactGA from "react-ga4";
import { WebsiteOffer, JakZaistniecWsieci, StronaFirmowaV1 } from "./pages";

posthog.init("phc_It2CeekK28CNy1Mptrd6ARQXJruy8ErUEFkJDbGYBcD", {
  api_host: "https://eu.posthog.com"
});

const GA_MEASUREMENT_ID = "G-KQQDJ0N0B5";

ReactGA.initialize(GA_MEASUREMENT_ID, { debug: true });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/sprzedaz/*" element={<SellingPage />} />
        <Route
          path="/oferta/strony_www/sukces_online"
          element={<WebsiteOffer />}
        />
        <Route path="/oferta/strony_www" element={<WebsiteOffer />} />
        <Route
          path="/oferta/strony_www/jak_zaistniec_w_sieci"
          element={<JakZaistniecWsieci />}
        />
        <Route
          path="/oferta/strony_www/strona-firmowa-v1"
          element={<StronaFirmowaV1 />}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
