import React from "react";
import "./LandingPagesExamples.css";
import { ExamplesForLandigData } from "../../../../../libs";

const LandingPagesExamples = () => {
  return (
    <div className="landingpagesexamples__kontener sekcja-padding">
      <div className="benefits__title">
        <h4 className="section-category">Przykładowe</h4>
        <h2 className="section-title">realizacje</h2>
      </div>
      <div className="realizacje__kontener">
        {ExamplesForLandigData.map((data, index) => {
          return (
            <div
              className="realizacje__example-kontener"
              key={data.link + index}>
              <div className="realizacje__example-img">
                <img src={data.img} alt={data.link} />
              </div>
              <div className="realizacje__example-opis"></div>
              <div className="realizacje__example-link">
                <a href={data.link} target="_blank">
                  <button> Link do strony</button>
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LandingPagesExamples;
