import React, {useState} from 'react'
import './OfferSelectPeriod.css'


   const selectPeriodButton = 
   [
     {
      title:"roczny"
     },
     {
      title:"miesięczny"
     }
   ]

const OfferSelectPeriod = ({setactiveButton,activeButton,setbuttonPeriodId,buttonPeriodId}) => {


  // setbuttonPeriodId(1)
   
  
  return (
    <div className='offerselectperiod__container'>
    {
      selectPeriodButton.map(
        (data,index)=> {
          return (
            <>
                  <button

      
      key={'buttonPeroid'+index}
      className={
        activeButton==index ? "offerselectperiod__container-button_active" : "offerselectperiod__container-button_notActive"
      }
      onClick={
       ()=> {

    
        setactiveButton(index)
        setbuttonPeriodId(index)

        // console.log(data.title,buttonPeriodId )
      }
      }
      >{data.title}</button>
      </>
          )
        }
      )
    }
      
    
    </div>
  )
}

export default OfferSelectPeriod