import React, { useState } from "react";
import "./Nav.css";
import { IoClose } from "react-icons/io5";
import ScrollIntoView from "react-scroll-into-view";
import { Logo } from "../../../libs";

const Nav = ({
  setcontactModule,
  setactiveWWW,
  setactiveSocialMedia,
  setsuwakActive,
  setsuwakIndex,
  setactiveRetouch,
  activeWWW,
  activeSocialMedia,
  activeRetouch,
  contactRef,
  setaboutUsActive
}) => {
  const [mobileMenu, setmobileMenu] = useState(false);

  // const readMoreHandler = () => setreadMore(true)

  const Menu = () => (
    <>
      <ScrollIntoView selector="#header">
        <p role="button" onClick={() => setmobileMenu(false)}>
          <a role="button" href="#main_page">
            strona główna
          </a>
        </p>
      </ScrollIntoView>
      <ScrollIntoView selector="#aboutUs">
        <p
          onClick={() => {
            // setaboutUsActive(true);
            setmobileMenu(false);
          }}>
          <a href="#about_us">o nas</a>
        </p>
      </ScrollIntoView>
      <ScrollIntoView selector="#strony_www">
        <p
          onClick={() => {
            setactiveWWW(true);
            // setsuwakActive(true)
            setmobileMenu(false);
          }}>
          <a role="button" a href="#strony_www">
            strony www
          </a>
        </p>
      </ScrollIntoView>
      <ScrollIntoView selector="#social_media">
        <p
          onClick={() => {
            setactiveSocialMedia(true);
            // setsuwakActive(true)
            setmobileMenu(false);
          }}>
          <a href="#social_media">Social Media</a>
        </p>
      </ScrollIntoView>
      <ScrollIntoView selector="#retusz_zdjec">
        <p
          onClick={() => {
            setactiveRetouch(true);
            // setsuwakActive(true)
            setmobileMenu(false);
          }}>
          <a href="#retusz">retusz</a>
        </p>
      </ScrollIntoView>
      <ScrollIntoView seletor="#contact">
        <p onClick={() => setmobileMenu(false)}>
          <a href="#contact">kontakt</a>
        </p>
      </ScrollIntoView>
    </>
  );

  return (
    <div className="nav__container">
      <div className="nav__sekcja-desktop">
        <div className="nav__sekcja-destop-section_container">
          <div className="nav__sekcja-destop-section-logo">
            <p>{Logo[0].title}</p>
          </div>
          <div className="nav__sekcja-destop-section-nav">
            <Menu />
          </div>
          <div className="nav__sekcja-destop-section-contact">
            <button
              onClick={() => {
                setcontactModule(true);
              }}>
              Skontaktuj się z nami
            </button>
          </div>
        </div>
      </div>
      <div className="nav__sekcja-mobile">
        <div className="nav__sekcja-mobile_container">
          <div className="nav__sekcja-mobile_logo">
            <p>{Logo[0].title}</p>
          </div>
          <div
            className="nav__sekcja-mobile_hamburgerIcon"
            role="button"
            onClick={() => setmobileMenu(true)}>
            <p>
              <span></span>
            </p>
            <p>
              <span></span>
            </p>
            <p>
              <span></span>
            </p>
          </div>
        </div>
      </div>
      {mobileMenu && (
        <div className="nav__sekcja-mobile-navContainer-active_overlay scale-up-hor-right">
          <div className="nav__sekcja-mobile-navContainer-active scale-up-hor-right sekcja-padding">
            <div className="nav__sekcja-mobile-navContainer-active_headSection">
              <button onClick={() => setmobileMenu(false)}>
                <IoClose />
              </button>
            </div>
            <div className="nav__sekcja-mobile-navContainer-active_menu-container">
              <Menu />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Nav;
