import {
  HeaderLandingPage01,
  HeaderLandingPage02,
  WWW03,
  WWW05
} from "../../../assets";

export const LandingPagesData = [
  {
    img: HeaderLandingPage01,
    title: "Sukces online?",
    sub_title: "Jest możliwy!",
    p1: "Czy interesuje Cię zwiększenie rozpoznawalności Twojej firmy?",
    p2: "Czy chcesz zwiększyc liczbę zamówień?",
    p_color: "#343a40"
  },
  {
    img: HeaderLandingPage02,
    title: "Jak zaistnieć w sieci?",
    sub_title: "Zastanawiasz się jak promować swoją firmę w internecie?",
    p1: "Przestań się zastanawiać, zdaj się na nas!",
    p2: "",
    p_color: "var(--secondary)"
  }
];

export const ExamplesForLandigData = [
  {
    img: WWW05,
    link: "https://altanka.twojamarkawsieci.pl"
  },
  {
    img: WWW03,
    link: "https://parafia.twojamarkawsieci.pl"
  }
];
