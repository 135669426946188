import React, { useEffect, useState } from "react";
import "./SocialMediaBenefits.css";
import { client, urlFor } from "../../../client";
import { BodyText } from "../../../components";
const SocialMediaBenefits = () => {
  const [benefit, setbenefit] = useState([]);
  const [headData, setHeadData] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `
      *[_type == "socialMedia_heads"]{
        socialMedia_benefits_head
      }`
      )
      .then((data) => setHeadData(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    client
      .fetch(
        `
      *[_type == "socialMedia_benefits"]
      `
      )
      .then((data) => setbenefit(data))
      .catch(console.error);
  }, []);

  return (
    <div className="socialmediabenefits__container ">
      <div className="socialmediabenefits__section sekcja-padding">
        <div className="socialmediabenefits__head-section">
          {headData.map((data, index) => {
            return (
              <>
                <h4
                  className="section-category"
                  key={
                    index +
                    data.socialMedia_benefits_head.category_name +
                    "kategoria"
                  }>
                  {" "}
                  {data.socialMedia_benefits_head.category_name}{" "}
                </h4>
                <h2
                  className="section-title"
                  key={index + data.socialMedia_benefits_head.title + "tytuł"}>
                  {data.socialMedia_benefits_head.title}
                </h2>
                <h2
                  className="section-subtitle"
                  key={
                    index +
                    data.socialMedia_benefits_head.sub_title +
                    "subtytuł"
                  }>
                  {data.socialMedia_benefits_head.sub_title}
                </h2>
              </>
            );
          })}
        </div>
        <div className="socialmediabenefits__benefitsset-container">
          {benefit.map((data, index) => {
            return (
              <>
                <div
                  className="socialmediabenefits__benefitsset-module_container"
                  key={index + data.benefit}>
                  <div className="benefitsset-module__img-section">
                    <img
                      src={data.imgUrl ? urlFor(data.imgUrl) : ""}
                      alt={data.title}
                    />
                  </div>
                  <div className="benefitsset-module__txt-section">
                    <h4>{data.benefit}</h4>
                    {data.text.map((text, index) => {
                      return (
                        <>
                          <p key={text.children[index]._key}>
                            {text.children[index].text}
                          </p>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SocialMediaBenefits;
