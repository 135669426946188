import React from "react";
import "./Offer.css";
import { WebsiteOfferModule } from "../../../components";
import { abonamentStronyWWW } from "../../../../../../libs";

const Offer = () => {
  return (
    <div className="sukcesonline__offer__kontener sekcja-padding">
      <div className="benefits__title">
        <h4 className="section-category">nasza oferta wykonania</h4>
        <h2 className="section-title">strony internetowej</h2>
      </div>
      <div className="sukcesonline__offer__kontenerOfert">
        {abonamentStronyWWW.map((data, index) => {
          return (
            <WebsiteOfferModule
              key={index + data.title}
              title={data.title}
              title_color={data.title_color}
              typ={data.typ}
              szablon_graficzny={data.szablon_graficzny}
              sekcje={data.sekcje}
              podstrony={data.podstrony}
              stock={data.stock}
              hosting={data.hosting}
              domena={data.domena}
              zabezpieczenia={data.zabezpieczenia}
              aktualizacja={data.aktualizacja}
              system_cms={data.system_cms}
              wsparcie_techniczne={data.wsparcie_techniczne}
              kopia_zapasowa={data.kopia_zapasowa}
              modyfikacje={data.modyfikacje}
              copywritting={data.copywritting}
              cena_rok={data.cena_rok}
            />
          );
        })}
      </div>
      <div className="sukcesonline__offer__dodatkowy_tekst">
        <h3>Kompleksowa opieka w abonamencie</h3>
        <p>
          Umowa z nami to kontrakt. A kontrakt oznacza obustronną relację opartą
          na wzajemnym zaufaniu i wsparciu. Tę deklarację traktujemy bardzo
          poważnie. Jest jedną z fundamentalnych wartości naszej agencji.
        </p>
        <p>
          Gwarantujemy stałą opiekę nad wszystkimi projektami, które nam
          powierzysz, przez cały czas trwania kontraktu. Warunki współpracy są
          zawsze klarowne i przejrzyste. Bez gwiazdek, drobnego druczku,
          niejasnych zapisów.
        </p>
        <p>
          Możliwość rozłożenia kwoty na okres trwania abonemntu, czyli na 12
          miesięcy
        </p>
        
      </div>

    </div>
  );
};

export default Offer;
