import React from "react";
import "./Stopka.css";
import { Link } from "react-router-dom";

const Stopka = () => {
  return (
    <div className="sukcesonline__stopka">
      <p>
        <Link to="/">www.twojamarkawsieci.pl</Link>
      </p>
    </div>
  );
};

export default Stopka;
