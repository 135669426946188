import React, { useState, useEffect } from "react";
import "./WywiadWWW.css";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const WywiadWWW = () => {
  const [mailValidationTrue, setmailValidationTrue] = useState(false);
  const [mailValidationFalse, setmailValidationFalse] = useState(false);
  const [mathQuestion, setMathQuestion] = useState(generateMathQuestion());
  const [userAnswer, setUserAnswer] = useState("");
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(1);

  function generateMathQuestion() {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    return `${num1} + ${num2}`;
  }

  const SendEmail = (object, values) => {
    // Mail do ustawienia
    emailjs
      .send("service_g2njj4p", "template_4pckw12", object, "YrILGE4XzgFDp-tKo")
      .then(
        (result) => {
          setmailValidationTrue(true);
          console.log(result.text, "Wiadomość wysłana poprawnie");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    setTimeout(() => {
      mailValidationTrue && setmailValidationTrue(false);
      mailValidationFalse && setmailValidationFalse(false);
    }, 4500);
  });

  return (
    <div className="wywiadwww__container">
      <div className="contact__section-container">
        <div className="formik__wrapper">
          <Formik
            initialValues={{
              subject: "Wywiad dotyczący Projektu",
              // Dane klineta
              nameAndsurname: "",
              email: "",
              phone: "",
              // Wywiad
              // Informacje o fimie
              companyName: "",
              whatCompanyDo: "",
              branza: "",
              cel_firmy: "",
              // Konkurencja
              konkurencja_firmy: "",
              konkurencja_dzialania: "",
              konkurencja_coMogePoprawic: "",
              konkurencja_coRobieLepiej: "",
              konkurencja_uwagi: "",

              // Klient
              opisKlienta: "",

              // Wygląd
              wyglad_kolorystyka: "",
              wyglad_typografia: "",
              wyglad_tresci: "",
              wyglad_inneStrony: "",
              wyglad_uwagi: "",

              // Dodatkowe uwagi
              textarea: ""
            }}
            validationSchema={Yup.object({
              nameAndsurname: Yup.string()
                .min(4, "Imię i naziwsko musi posiadać minimum 4 znaki")
                .max(30, "Imię i nazwisko musi posiadać maksimum 30 znaków")
                .required("Pole nie może być puste"),
              email: Yup.string()
                .email("Błędny format e-mail")
                .min(4, "E-mail musi posiadać minimum 4 znaki")
                .max(30, "E-mail musi posiadać maksimum 30 znaków")
                .required("Pole nie może być puste"),
              phone: Yup.number()
                .positive()
                .integer()
                // .string()
                // .max(12, "Telefon musi posiadać maksimum 12 znaków")
                .typeError("Pole telefon może zawierać tylko cyfry")
                .min(9, "Telefon musi posiadać minimum 9 znaków")
                .required("Pole nie może być puste"),
              subject: Yup.string()
                .min(4, "Temat musi posiadać minimum 4 znaki")
                .max(30, "Temat musi posiadać maksimum 30 znaków")
                .required("Pole nie może być puste"),
              textarea: Yup.string()
                .min(30, "Treść wiadomości musi posiadać minimum 30 znaków")
                .required("Pole nie może być puste")
            })}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                // Sprawdź, czy odpowiedź użytkownika jest poprawna
                if (parseInt(userAnswer) === eval(mathQuestion)) {
                  setIsAnswerCorrect(true);
                  SendEmail(values);
                  actions.setSubmitting(false);
                  actions.resetForm({
                    values: ""
                  });
                  setUserAnswer("");
                  console.log(values);
                } else {
                  setIsAnswerCorrect(false);
                }
              }, 1000);
            }}>
            <Form className="formik__container formik__sprzedaz">
              <div className="inputs_container">
                {/* // Dane klienta  */}
                <div className="formik__title-section">
                  <h3>Dane Klienta</h3>
                </div>
                <div className="formik__sekcja">
                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="nameAndsurname"
                      id="nameAndsurname"
                      required
                    />
                    <ErrorMessage
                      name="nameAndsurname"
                      component="label"
                      className="errorMessage__label"
                    />
                    <label className="plaholder__label" htmlFor="">
                      Imię i nazwisko
                    </label>
                  </div>

                  <div className="formik_field-container">
                    <Field
                      className="formik__field"
                      name="email"
                      id="email"
                      required
                    />
                    <ErrorMessage
                      name="email"
                      component="label"
                      className="errorMessage__label"
                    />
                    <label className="plaholder__label" htmlFor="">
                      Adres e-mail
                    </label>
                  </div>

                  <div className="formik_field-container">
                    <Field
                      className="formik__field"
                      name="phone"
                      id="phone"
                      required
                    />
                    <ErrorMessage
                      name="phone"
                      component="label"
                      className="errorMessage__label"
                    />
                    <label className="plaholder__label" htmlFor="">
                      Nr telefonu
                    </label>
                  </div>
                </div>
              </div>
              {/* // Dane firmy  */}
              <div className="inputs_container">
                <div className="formik__title-section ">
                  <h3>Dane Firmy</h3>
                </div>
                <div className="formik__sekcja  formik-4">
                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="companyName"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      Nazwa Firmy
                    </label>
                  </div>
                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="branza"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      Czym firma się zajmuje
                    </label>
                  </div>
                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="whatCompanyDo"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      W jakiej branży działa
                    </label>
                  </div>
                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="cel_firmy"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      Jaki jest cel firmy?
                    </label>
                  </div>
                </div>
              </div>

              {/* KONKURENCJA */}
              <div className="inputs_container">
                <div className="formik__title-section ">
                  <h3>Konkurencja</h3>
                </div>
                <div className="formik__sekcja  formik-4">
                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="konkurencja_firmy"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      Jakie / Które firmy są największą konkurencją
                    </label>
                  </div>
                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="konkurencja_dzialania"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      Co konkurencja robi lepiej lub czym się wyróżnia?
                    </label>
                  </div>
                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="konkurencja_coMogePoprawic"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      Co może firma poprawić aby działać lepiej?
                    </label>
                  </div>
                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="konkurencja_coRobieLepiej"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      Czym firma wyróżnia się na tle konkurencji?
                    </label>
                  </div>
                </div>
                <div className="textarea__container">
                  <div className="formik_field-container">
                    <Field
                      type="textarea"
                      className="formik__field"
                      name="konkurencja_uwagi"
                      id="textarea"
                      as="textarea"
                      control="textarea"
                      required
                    />
                    <label className="plaholder__label" htmlFor="">
                      Dodatkowe Uwagi
                    </label>
                    <ErrorMessage
                      name="textarea"
                      component="label"
                      className="errorMessage__label"
                    />
                  </div>
                </div>
              </div>

              {/* PROFIL KLIENTA  */}
              <div className="textarea__container">
                <div className="formik__title-section ">
                  <h3>Profil Klienta</h3>
                </div>
                <div className="formik_field-container">
                  <Field
                    type="textarea"
                    className="formik__field"
                    name="opisKlienta"
                    id="textarea"
                    as="textarea"
                    control="textarea"
                    required
                  />
                  <label className="plaholder__label" htmlFor="">
                    Grupa docelowa: Kto jest główną grupą docelową dla tej
                    strony? (np. wiek, płeć, zainteresowania, lokalizacja)
                  </label>
                </div>
              </div>

              {/* Szczegóły dotyczące wyglądu */}
              <div className="inputs_container">
                <div className="formik__title-section ">
                  <h3>Szczegóły dotyczące wyglądu</h3>
                </div>
                <div className="formik__sekcja  formik-4">
                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="wyglad_kolorystyka"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      Kolorystyka
                    </label>
                  </div>
                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="wyglad_typografia"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      Typografia
                    </label>
                  </div>
                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="wyglad_tresci"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      Czy są treści, które można przesłać? np. zdjęcia, teksty
                    </label>
                  </div>
                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="wyglad_inneStrony"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      Strony, którymi się wzoruje
                    </label>
                  </div>
                  <div className="textarea__container">
                    <div className="formik_field-container">
                      <Field
                        type="textarea"
                        className="formik__field"
                        name="wyglad_uwagi"
                        id="textarea"
                        as="textarea"
                        control="textarea"
                        required
                      />
                      <label className="plaholder__label" htmlFor="">
                        Dodatkowe Uwagi
                      </label>
                      <ErrorMessage
                        name="textarea"
                        component="label"
                        className="errorMessage__label"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="formik__title-section ">
                <h3>Uwagi niezwiązane z wcześniejszymi pytaniami</h3>
              </div>
              <div className="textarea__container">
                <div className="formik_field-container">
                  <Field
                    type="textarea"
                    className="formik__field"
                    name="textarea"
                    id="textarea"
                    as="textarea"
                    control="textarea"
                    required
                  />
                  <label className="plaholder__label" htmlFor="">
                    Treść
                  </label>
                  <ErrorMessage
                    name="textarea"
                    component="label"
                    className="errorMessage__label"
                  />
                </div>
              </div>
              <div className="formik__question">
                <label htmlFor="mathQuestion" className="formik__field">
                  <p>{mathQuestion} = </p>
                </label>
                <Field
                  type="text"
                  id="mathQuestion"
                  name="mathQuestion"
                  value={userAnswer}
                  onChange={(e) => setUserAnswer(e.target.value)}
                />
              </div>
              {!isAnswerCorrect && (
                <div className="formik__question-error">
                  <p>Nieprawidłowa odpowiedź.</p>
                </div>
              )}
              <div className="formiButton__container">
                <button className="formik_button" type="submit">
                  Wyślij
                </button>
              </div>
            </Form>
          </Formik>
          {mailValidationTrue && (
            <div className="formik__messeageAfterSubmitTrue">
              <p>Wiadomość została wysłana poprawnie</p>
            </div>
          )}
          {mailValidationFalse && (
            <div className="formik__messeageAfterSubmitFalse">
              <p>Wiadomość nie została wysłana, spróbuj ponownie</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WywiadWWW;
