import React, { useState, useEffect } from "react";
import "./RetouchExamples.css";
import { client } from "../../../client";
import { ExmapleList, PaginationExmaple } from "../../../components";

const RetouchExamples = ({
  readMore,
  setreadMore,
  setid,
  setdoc,
  doc,
  setimgFull,
  settitle,
  setlink,
  setimg,
  setimgBefore
}) => {
  const [exampleList, setexampleList] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "retouch_heads"]{
          retouch_examples_head
        }`
      )
      .then((data) => setheadData(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    client
      .fetch(`*[_type == "retouch_exmaples" ] | order(releaseDate desc)`)
      .then((data) => setexampleList(data))
      .catch(console.error);
  }, []);

  // console.log(exampleList);
  const [headData, setheadData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [postPerPage, setpostPerPage] = useState(6);
  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;

  const currentPost = exampleList.slice(firstPostIndex, lastPostIndex);

  return (
    <div className="retouchexamples__container sekcja-padding">
      <div className="retouchexamples__head-section">
        {headData.map((data, index) => {
          return (
            <>
              <h4
                className="section-category"
                key={
                  index + data.retouch_examples_head.category_name + "kategoria"
                }>
                {" "}
                {data.retouch_examples_head.category_name}{" "}
              </h4>
              <h2
                className="section-title"
                key={index + data.retouch_examples_head.title + "tytuł"}>
                {data.retouch_examples_head.title}
              </h2>
              <h2
                className="section-subtitle"
                key={index + data.retouch_examples_head.sub_title + "subtytuł"}>
                {data.retouch_examples_head.sub_title}
              </h2>
            </>
          );
        })}
      </div>
      <div className="examples__modules_container">
        <ExmapleList
          readMore={readMore}
          setreadMore={setreadMore}
          exmapleData={currentPost}
          setid={setid}
          setdoc={setdoc}
          doc={doc}
          setimgFull={setimgFull}
          settitle={settitle}
          setlink={setlink}
          setimg={setimg}
          setimgBefore={setimgBefore}
        />

        <PaginationExmaple
          totalPosts={exampleList.length}
          postPerPage={postPerPage}
          setcurrentPage={setcurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default RetouchExamples;
