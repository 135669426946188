import React from 'react'
import './TopScroller.css'
import {MdKeyboardArrowUp} from 'react-icons/md'
import ScrollIntoView from 'react-scroll-into-view'

const TopScroller = ({topScrollerActive,isTopScrollerVisible}) => {
  return (
    <div className={`topscroller__container ${topScrollerActive ? 'scale-up-center' : 'scale-down-center'}`}>
        <ScrollIntoView selector='#header'>
       <button> <MdKeyboardArrowUp/></button>
       </ScrollIntoView>
        
    </div>
  )
}

export default TopScroller