import React, {useState} from 'react'
import {MdKeyboardArrowUp, MdKeyboardArrowDown} from 'react-icons/md'
import './Suwak.css'

const Suwak = ({title,suwakActive,id,setsuwakActive,setsuwakIndex,suwakIndex,setactiveWWW,activeWWW,activeSocialMedia,setactiveSocialMedia,activeRetouch, setactiveRetouch}) => {


  return (
    <div className={activeWWW || activeSocialMedia || activeRetouch ?'suwakidzialow_suwak_active': "suwakidzialow_suwak"} role='button'

  
    onClick={
        ()=> {
            
        
            setsuwakIndex(id)  

            if (id === 0) {
                setactiveWWW(!activeWWW)
             
            }
            if (id === 1) {
                setactiveSocialMedia(!activeSocialMedia)
                
            }
            
            if (id === 2) {
                setactiveRetouch(!activeRetouch)
                
            }

        }
    }
   
    >
        
    <div className={'suwakidzialow_suwak_title-section'}>
        <h4
        
        >{title}</h4>
    </div>
    <div className='suwakidzialow_suwak_arrowButton'>
        <span><MdKeyboardArrowUp/></span>
    </div>

</div>
  )
}

export default Suwak