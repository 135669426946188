import React, { useEffect, useState } from "react";
import "./WebistesExamples.css";
import { client } from "../../../client";
import { PaginationExmaple, ExmapleList } from "../../../components";

const WebsitesExamples = ({
  readMore,
  setreadMore,
  setid,
  doc,
  setdoc,
  title,
  settitle,
  setimgFull,
  setlink,
  setimg,
  setimgBefore
}) => {
  const [exampleList, setexampleList] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "websites_heads"]{
          websites_examples_head
        }`
      )
      .then((data) => setheadData(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    client
      .fetch(`*[_type == "website_exmaples"]| order(releaseDate desc)`)
      .then((data) => setexampleList(data))
      .catch(console.error);
  }, []);

  const [headData, setheadData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [postPerPage, setpostPerPage] = useState(6);
  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;

  const currentPost = exampleList.slice(firstPostIndex, lastPostIndex);

  return (
    <div className="websitesexamples__container sekcja-padding">
      <div className="websitesexamples__head-section">
        {headData.map((data, index) => {
          return (
            <>
              <h4
                className="section-category"
                key={
                  index +
                  data.websites_examples_head.category_name +
                  "kategoria"
                }>
                {" "}
                {data.websites_examples_head.category_name}{" "}
              </h4>
              <h2
                className="section-title"
                key={index + data.websites_examples_head.title + "tytuł"}>
                {data.websites_examples_head.title}
              </h2>
              <h2
                className="section-subtitle"
                key={
                  index + data.websites_examples_head.sub_title + "subtytuł"
                }>
                {data.websites_examples_head.sub_title}
              </h2>
            </>
          );
        })}
      </div>
      <div className="examples__modules_container">
        <ExmapleList
          exmapleData={currentPost}
          readMore={readMore}
          setreadMore={setreadMore}
          setid={setid}
          setdoc={setdoc}
          doc={doc}
          title={title}
          settitle={settitle}
          setimgFull={setimgFull}
          setlink={setlink}
          setimg={setimg}
          setimgBefore={setimgBefore}
        />

        <PaginationExmaple
          totalPosts={exampleList.length}
          postPerPage={postPerPage}
          setcurrentPage={setcurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default WebsitesExamples;
