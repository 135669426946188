import React from "react";
import "./JakZaistniecWsieci.css";
import {
  Benefits,
  Contact,
  DlaczegoMy,
  LandingPagesExamples,
  StopkaSukces,
  SukcesOnlineOffer,
  WebsiteOfferHeader
} from "./containers";
import { MenuWebsiteOffer } from "./components";
import ReactGA from "react-ga4";
import { LandingPagesData } from "../../../libs";

const GA_MEASUREMENT_ID = "G-8P6MB22LZE";

ReactGA.initialize(GA_MEASUREMENT_ID, { debug: true });

const JakZaistniecWsieci = () => {
  return (
    <div className="websiteoffer__kontener">
      <div className="websiteoffer-menuMobile__kontener">
        <MenuWebsiteOffer />
      </div>
      <WebsiteOfferHeader
        img={LandingPagesData[1].img}
        title={LandingPagesData[1].title}
        sub_title={LandingPagesData[1].sub_title}
        p1={LandingPagesData[1].p1}
        p2={LandingPagesData[1].p2}
      />
      <Benefits />
      <DlaczegoMy />
      <LandingPagesExamples />
      <SukcesOnlineOffer />
      <Contact />
      <StopkaSukces />
    </div>
  );
};

export default JakZaistniecWsieci;
