import React from "react";
import "./Benefits.css";
import { AiFillCheckCircle } from "react-icons/ai";

const Benefits = () => {
  return (
    <div className="benefits__kontener sekcja-padding" id="korzysci">
      <div className="benefits__title">
        <h4 className="section-category">Dlaczego do Twojej działałności </h4>
        <h2 className="section-title">
          potrzebujesz odpowiedniej <span>strony internetowej</span>
        </h2>
      </div>
      <div className="benefits__paragraph">
        <p>
          Strona jest ważnym nośnikiem informacji o Tobie i firmie. Nie zastąpi
          jej żadna wizytówka w Google ani profile w mediach społecznościowych.
          Witryna jest Twoją własnością, będzie więc istnieć stale, czego nie
          można powiedzieć o Facebooku lub innych portalach.
        </p>
      </div>
      <div className="benefits__paragraph">
        <p className="benefits__paragraph-pogrubione">
          Czy wiesz, że silna więź między klientem a marką rodzi już podczas
          pierwszej wizyty na stronie?
        </p>
        <p>
          Przejrzysta, umiejętnie zaprojektowana architektura daje odwiedzającym
          informacje:
        </p>
        <div className="benefits__paragraph-opcje">
          <p>
            <span className="benefits__paragraph-opcje_bullspan">
              <AiFillCheckCircle />
            </span>
            <span className="benefits__paragraph-opcje_wyroznione">
              O Tobie – Twej firmie i marce osobistej.
            </span>{" "}
            Jest bazą wiedzy na temat Twojej osoby, jak również tego, czym się
            zajmujesz, w czym możesz pomóc, co warto zrobić razem z Tobą. Weź
            pod uwagę, że słowa te czytasz właśnie na{" "}
            <span className="benefits__paragraph-opcje_wyroznione">
              stronie internetowej{" "}
            </span>
            – skorzystaj z potencjału tej formy komunikacji wolnej od zawirowań
            oraz kaprysów algorytmów stosowanych w mediów społecznościowych.
          </p>
          <p>
            <span className="benefits__paragraph-opcje_bullspan">
              <AiFillCheckCircle />
            </span>
            <span className="benefits__paragraph-opcje_wyroznione">
              O wartościach
            </span>{" "}
            – za każdą marką, firmą i osobowością stoi to, czym kierujemy się w
            życiu oraz w biznesie. Kwestie o znaczeniu fundamentalnym dla każdej
            jednostki. Jak inaczej możesz je komunikować, jeśli nie przez stronę
            internetową? Tu masz okazję wyjaśnić, jak przekładają się one na
            codzienność, jakie{" "}
            <span className="benefits__paragraph-opcje_wyroznione">
              sukcesy
            </span>{" "}
            dzięki nim osiągasz. Odkrywasz nieco karty,{" "}
            <span className="benefits__paragraph-opcje_wyroznione">
              budujesz głębokie relacje
            </span>
            .
          </p>
          <p>
            <span className="benefits__paragraph-opcje_bullspan">
              <AiFillCheckCircle />
            </span>
            <span className="benefits__paragraph-opcje_wyroznione">
              {" "}
              O produktach oraz usługach
            </span>{" "}
            – prowadząc e-sprzedaż, działasz 24 godziny na dobę, 7 dni w
            tygodniu i nawet nie musisz ruszać się z domu. Tymczasem kupujący
            znajdują, widzą i poznają Twoją ofertę w wygodnych fotelach albo
            czekając w kolejce. Sprzedaż online to przecież trend, który rośnie
            i będzie się już tylko nasilał.{" "}
            <span className="benefits__paragraph-opcje_wyroznione">
              Korzystaj z tego.
            </span>
          </p>
          <p>
            <span className="benefits__paragraph-opcje_bullspan">
              <AiFillCheckCircle />
            </span>
            <span className="benefits__paragraph-opcje_wyroznione">
              O wiedzy
            </span>{" "}
            – sekcja blogowa jest tego najlepszym przykładem. Artykuły i
            poradniki budują Twój autorytet w oczach czytelników oraz botów
            wyszukiwarek internetowych. Zamieszczając wartościowe, łatwe do
            przyswojenia treści, stajesz się ekspertem w branży. Szanowanym i
            docenianym. Udowadniasz, że znasz się na rzeczy i warto Ci zaufać.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
