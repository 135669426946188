import { client } from "../../client";

const logo = [];

client
  .fetch(`*[_type == "logo"][0]`)
  .then((data) => {
    logo.push(data.title);
  })
  .catch(console.error);



export default [
  {
    title: logo
  }
];
