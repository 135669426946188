import React from "react";
import "./Websites.css";
import {
  WebsitesExampels,
  WebsitesBenefits,
  WebsitesOffer,
  WebistesCare
} from "../../containers";

const Websites = ({
  readMore,
  setreadMore,
  setid,
  doc,
  setdoc,
  title,
  settitle,
  setimgFull,
  setlink,
  setimg,
  topScrollerRef,
  setimgBefore
}) => {
  return (
    <div className="websites__container" ref={topScrollerRef}>
      <WebsitesBenefits />
      <WebsitesExampels
        readMore={readMore}
        setreadMore={setreadMore}
        setid={setid}
        setdoc={setdoc}
        doc={doc}
        title={title}
        settitle={settitle}
        setimgFull={setimgFull}
        setlink={setlink}
        setimg={setimg}
        setimgBefore={setimgBefore}
      />
      <WebsitesOffer />
      <WebistesCare />
    </div>
  );
};

export default Websites;
