import React from "react";
import "./MenuWebsiteOffer.css";
import { Link } from "react-router-dom";

const MenuWebsiteOffer = () => {
  return (
    <div className="menuwebsiteoffer__kontener">
      <p>
        <Link to="/">twojamarkawsieci.pl</Link>
      </p>
    </div>
  );
};

export default MenuWebsiteOffer;
