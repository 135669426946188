import React from 'react'
import './PaginationExample.css'

const PaginationExample = ({totalPosts,postPerPage, setcurrentPage,currentPage}) => {
    let pages = []
    for ( let i  = 1; i<= Math.ceil(totalPosts/postPerPage); i++) {
        pages.push(i)
    }
  return (
    <div className='pagginationExample__container'>
            <button key={'prev_button'} className={currentPage === 1 ?'pagginationExample__buttons-deactive': 'pagginationExample__buttons'} onClick={
                ()=> {
                    setcurrentPage(currentPage - 1)
                if(currentPage === 1) {
                    setcurrentPage(currentPage)
                }
                }
            }>
            {`<<`}
            </button>

        {
            pages.map(
                (page,index) => {
                    return (
                        <>
                   
                        <button
                        className={page == currentPage ? 'pagginationExample_page-button_active':'pagginationExample_page-button'} 
                        key={index+"title_pagination_button"} 
                        onClick={()=> setcurrentPage(page)}
                      
                        
                        >{page}</button>
                        </>
                    )
                }
            )
        }
                    <button key={'next_button'} className={currentPage === Math.ceil(totalPosts/postPerPage) ?'pagginationExample__buttons-deactive': 'pagginationExample__buttons'} onClick={
                ()=> {
                    setcurrentPage(currentPage + 1)
                if(currentPage === Math.ceil(totalPosts/postPerPage)) {
                    setcurrentPage(currentPage)
                }
                }
            }>
            {`>>`}
            </button>
    </div>
  )
}

export default PaginationExample