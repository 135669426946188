import React, { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { Formik, Field, Form, useField, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Contact.css";

const Contact = () => {
  const [mailValidationTrue, setmailValidationTrue] = useState(false);
  const [mailValidationFalse, setmailValidationFalse] = useState(false);
  const [mathQuestion, setMathQuestion] = useState(generateMathQuestion());
  const [userAnswer, setUserAnswer] = useState("");
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(1);

  function generateMathQuestion() {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    return `${num1} + ${num2}`;
  }

  const SendEmail = (object, values) => {
    emailjs
      .send("service_b5li8mh", "template_4pckw12", object, "YrILGE4XzgFDp-tKo")
      .then(
        (result) => {
          setmailValidationTrue(true);
          console.log(result.text, "Wiadomość wysłana poprawnie");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    setTimeout(() => {
      mailValidationTrue && setmailValidationTrue(false);
      mailValidationFalse && setmailValidationFalse(false);
    }, 3500);
  });
  return (
    <div className="contact__kontener">
      <div className="contact__kontener-zawartosc sekcja-padding">
        <div className="benefits__title">
          <h4 className="section-category">nie marnuj już czasu i pieniędzy</h4>
          <h2 className="section-title">Nie czekaj!</h2>
          <h4
            className="section-category"
            style={{ color: `var(--secondText)`, marginTop: "1rem" }}>
            zostaw nr telefonu, a skontaktujemy się z Tobą
          </h4>
          {/* <h4
            className="section-category"
            style={{ color: `var(--secondText)`, marginTop: "1rem" }}>
            skontaktuj się z nami
          </h4> */}
        </div>

        <div className="kontakt__kontener">
          <div className="kontakt__formularz">
            <div className="formik__wrapper">
              <Formik
                initialValues={{
                  nameAndsurname: "",
                  phone: "",
                  subject: "Prośba o kontakt z landingPage - Sukces"
                }}
                validationSchema={Yup.object({
                  nameAndsurname: Yup.string()
                    .min(3, "Imię i naziwsko musi posiadać minimum 3 znaki")
                    .max(30, "Imię i nazwisko musi posiadać maksimum 30 znaków")
                    .required("Pole nie może być puste"),

                  phone: Yup.number()
                    .positive()
                    .integer()
                    .typeError("Pole telefon może zawierać tylko cyfry")
                    .min(7, "Telefon musi posiadać minimum 7 znaków")
                    .required("Pole nie może być puste")
                })}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    // Sprawdź, czy odpowiedź użytkownika jest poprawna
                    if (parseInt(userAnswer) === eval(mathQuestion)) {
                      setIsAnswerCorrect(true);
                      SendEmail(values);
                      actions.setSubmitting(false);
                      actions.resetForm({
                        values: ""
                      });
                      setUserAnswer("");
                      console.log(values);
                    } else {
                      setIsAnswerCorrect(false);
                    }
                  }, 1000);
                }}>
                <Form className="formik__container">
                  <div className="inputs_container">
                    <div className="formik_field-container">
                      <Field
                        type="input"
                        className="formik__field"
                        name="nameAndsurname"
                        id="nameAndsurname"
                        required
                      />
                      <ErrorMessage
                        name="nameAndsurname"
                        component="label"
                        className="errorMessage__label"
                      />
                      <label className="plaholder__label" htmlFor="">
                        Imię
                      </label>
                    </div>

                    <div className="formik_field-container">
                      <Field
                        className="formik__field"
                        name="phone"
                        id="phone"
                        required
                      />
                      <ErrorMessage
                        name="phone"
                        component="label"
                        className="errorMessage__label"
                      />
                      <label className="plaholder__label" htmlFor="">
                        Nr telefonu
                      </label>
                    </div>
                  </div>

                  <div className="formik__question">
                    <label htmlFor="mathQuestion" className="formik__field">
                      <p>{mathQuestion} = </p>
                    </label>
                    <Field
                      type="text"
                      id="mathQuestion"
                      name="mathQuestion"
                      value={userAnswer}
                      onChange={(e) => setUserAnswer(e.target.value)}
                    />
                  </div>
                  {!isAnswerCorrect && (
                    <div className="formik__question-error">
                      <p>Nieprawidłowa odpowiedź.</p>
                    </div>
                  )}
                  <div className="formiButton__container">
                    <button className="formik_button" type="submit">
                      Wyślij
                    </button>
                  </div>
                </Form>
              </Formik>
              {mailValidationTrue && (
                <div className="formik__messeageAfterSubmitTrue">
                  <p>Wiadomość została wysłana poprawnie</p>
                </div>
              )}
              {mailValidationFalse && (
                <div className="formik__messeageAfterSubmitFalse">
                  <p>Wiadomość nie została wysłana, spróbuj ponownie</p>
                </div>
              )}
            </div>
          </div>
          <div className="kontakt__zawartosc_doPDF">
            <div className="kontakt__zawartosc_doPDF-telefon">
              <p>+48 726 - 472 - 762</p>
            </div>
            <div className="kontakt__zawartosc_doPDF-mail">
              <p>kontakt@twojamarkawsieci.pl</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
