import React, { useState, useEffect } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { Formik, Field, Form, useField, ErrorMessage } from "formik";
import * as Yup from "yup";

const Contact = ({ contactRef, isContactVisible }) => {
  const [mailValidationTrue, setmailValidationTrue] = useState(false);
  const [mailValidationFalse, setmailValidationFalse] = useState(false);
  const [mathQuestion, setMathQuestion] = useState(generateMathQuestion());
  const [userAnswer, setUserAnswer] = useState("");
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(1);

  function generateMathQuestion() {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    return `${num1} + ${num2}`;
  }

  const SendEmail = (object, values) => {
    emailjs
      .send("service_b5li8mh", "template_4pckw12", object, "YrILGE4XzgFDp-tKo")
      .then(
        (result) => {
          setmailValidationTrue(true);
          console.log(result.text, "Wiadomość wysłana poprawnie");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    setTimeout(() => {
      mailValidationTrue && setmailValidationTrue(false);
      mailValidationFalse && setmailValidationFalse(false);
    }, 3500);
  }, []);

  return (
    <div className="contact__container" ref={contactRef}>
      {
        // isContactVisible ? console.log('kontakt widoczny') : console.log('kontakt niewidoczny')
      }
      <div className="contact__section-container sekcja-padding" id="contact">
        <div className="formik__wrapper">
          <Formik
            initialValues={{
              nameAndsurname: "",
              email: "",
              phone: "",
              subject: "",
              textarea: ""
            }}
            validationSchema={Yup.object({
              nameAndsurname: Yup.string()
                .min(4, "Imię i naziwsko musi posiadać minimum 4 znaki")
                .max(30, "Imię i nazwisko musi posiadać maksimum 30 znaków")
                .required("Pole nie może być puste"),
              email: Yup.string()
                .email("Błędny format e-mail")
                .min(4, "E-mail musi posiadać minimum 4 znaki")
                .max(30, "E-mail musi posiadać maksimum 30 znaków")
                .required("Pole nie może być puste"),
              phone: Yup.number()
                .positive()
                .integer()
                // .string()
                // .max(12, "Telefon musi posiadać maksimum 12 znaków")
                .typeError("Pole telefon może zawierać tylko cyfry")
                .min(9, "Telefon musi posiadać minimum 9 znaków")
                .required("Pole nie może być puste"),
              subject: Yup.string()
                .min(4, "Temat musi posiadać minimum 4 znaki")
                .max(30, "Temat musi posiadać maksimum 30 znaków")
                .required("Pole nie może być puste"),
              textarea: Yup.string()
                .min(30, "Treść wiadomości musi posiadać minimum 30 znaków")
                .required("Pole nie może być puste")
            })}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                // Sprawdź, czy odpowiedź użytkownika jest poprawna
                if (parseInt(userAnswer) === eval(mathQuestion)) {
                  setIsAnswerCorrect(true);
                  SendEmail(values);
                  actions.setSubmitting(false);
                  actions.resetForm({
                    values: ""
                  });
                  setUserAnswer("");
                  console.log(values);
                } else {
                  setIsAnswerCorrect(false);
                }
              }, 1000);
            }}>
            <Form className="formik__container">
              <div className="inputs_container">
                <div className="formik_field-container">
                  <Field
                    type="input"
                    className="formik__field"
                    name="nameAndsurname"
                    id="nameAndsurname"
                    required
                  />
                  <ErrorMessage
                    name="nameAndsurname"
                    component="label"
                    className="errorMessage__label"
                  />
                  <label className="plaholder__label" htmlFor="">
                    Imię i nazwisko
                  </label>
                </div>

                <div className="formik_field-container">
                  <Field
                    className="formik__field"
                    name="email"
                    id="email"
                    required
                  />
                  <ErrorMessage
                    name="email"
                    component="label"
                    className="errorMessage__label"
                  />
                  <label className="plaholder__label" htmlFor="">
                    Adres e-mail
                  </label>
                </div>

                <div className="formik_field-container">
                  <Field
                    className="formik__field"
                    name="phone"
                    id="phone"
                    required
                  />
                  <ErrorMessage
                    name="phone"
                    component="label"
                    className="errorMessage__label"
                  />
                  <label className="plaholder__label" htmlFor="">
                    Nr telefonu
                  </label>
                </div>

                <div className="formik_field-container">
                  <Field
                    className="formik__field"
                    name="subject"
                    id="subject"
                    required
                  />
                  <ErrorMessage
                    name="subject"
                    component="label"
                    className="errorMessage__label"
                  />
                  <label className="plaholder__label" htmlFor="">
                    Temat
                  </label>
                </div>
              </div>

              <div className="textarea__container">
                <div className="formik_field-container">
                  <Field
                    type="textarea"
                    className="formik__field"
                    name="textarea"
                    id="textarea"
                    as="textarea"
                    control="textarea"
                    required
                  />
                  <label className="plaholder__label" htmlFor="">
                    Treść
                  </label>
                  <ErrorMessage
                    name="textarea"
                    component="label"
                    className="errorMessage__label"
                  />
                </div>
              </div>
              <div className="formik__question">
                <label htmlFor="mathQuestion" className="formik__field">
                  <p>{mathQuestion} = </p>
                </label>
                <Field
                  type="text"
                  id="mathQuestion"
                  name="mathQuestion"
                  value={userAnswer}
                  onChange={(e) => setUserAnswer(e.target.value)}
                />
              </div>
              {!isAnswerCorrect && (
                <div className="formik__question-error">
                  <p>Nieprawidłowa odpowiedź.</p>
                </div>
              )}
              <div className="formiButton__container">
                <button className="formik_button" type="submit">
                  Wyślij
                </button>
              </div>
            </Form>
          </Formik>
          {mailValidationTrue && (
            <div className="formik__messeageAfterSubmitTrue">
              <p>Wiadomość została wysłana poprawnie</p>
            </div>
          )}
          {mailValidationFalse && (
            <div className="formik__messeageAfterSubmitFalse">
              <p>Wiadomość nie została wysłana, spróbuj ponownie</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
