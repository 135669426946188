import React, { useState } from "react";

import "./SuwakiDzialow.css";
import Suwak from "./Suwak/Suwak";

const suwakList = [
  {
    title: "Strony WWW"
  },
  {
    title: "Grafiki"
  },
  {
    title: "Retusz Zdjęć"
  }
];

const SuwakiDzialow = ({
  children,
  activeWWW,
  activeSocialMedia,
  activeRetouch,
  setactiveWWW,
  setactiveSocialMedia,
  setactiveRetouch,
  suwakActive,
  setsuwakActive,
  suwakIndex,
  setsuwakIndex,
  topScrollerActive,
  topScrollerRef
}) => {
  return (
    <div className="suwakidzialow__container">
      <div
        className="suwakidzialow__container-section"
        style={activeWWW ? { margin: "2rem 0 1rem 0" } : {}}>
        <Suwak
          key={"suwak" + 1}
          title={"Strony WWW"}
          suwakActive={suwakActive}
          id={0}
          setsuwakActive={setsuwakActive}
          suwakIndex={suwakIndex}
          setsuwakIndex={setsuwakIndex}
          setactiveWWW={setactiveWWW}
          activeWWW={activeWWW}
        />
      </div>
      <div
        className="suwakdzialow__children-container scale-up-top"
        id="strony_www">
        {activeWWW && children[0]}
      </div>
      <div
        className="suwakidzialow__container-section"
        style={activeSocialMedia ? { margin: "2rem 0 1rem 0" } : {}}>
        <Suwak
          key={"suwak" + 2}
          title={"Social Media"}
          suwakActive={suwakActive}
          id={1}
          setsuwakActive={setsuwakActive}
          suwakIndex={suwakIndex}
          setsuwakIndex={setsuwakIndex}
          activeSocialMedia={activeSocialMedia}
          setactiveSocialMedia={setactiveSocialMedia}
        />
      </div>
      <div
        className="suwakdzialow__children-container scale-up-top"
        id="social_media"
        ref={topScrollerRef}>
        {activeSocialMedia && children[1]}
      </div>

      <div
        className="suwakidzialow__container-section"
        style={activeRetouch ? { margin: "2rem 0 1rem 0" } : {}}>
        <Suwak
          key={"suwak" + 3}
          title={"Retusz Produtkowy"}
          suwakActive={suwakActive}
          id={2}
          setsuwakActive={setsuwakActive}
          suwakIndex={suwakIndex}
          setsuwakIndex={setsuwakIndex}
          activeRetouch={activeRetouch}
          setactiveRetouch={setactiveRetouch}
        />
      </div>
      <div
        className="suwakdzialow__children-container scale-up-top"
        id="retusz_zdjec"
        ref={topScrollerRef}>
        {activeRetouch && children[2]}
      </div>
    </div>
  );
};

export default SuwakiDzialow;
