import React, { useRef } from "react";

import "./StronaFirmowaV1.css";
import {
  ProductFirstLook,
  SekcjaOpisowa,
  SprzedazKontakt
} from "../components/";
import {
  StronaFirmowaV1_Sekcja_01,
  StronaFirmowaV1_Sekcja_02,
  StronaFirmowaV1_Sekcja_03,
  StronaFirmowaV1_Sekcja_04,
  StronaFirmowaV1_Sekcja_05,
  StronaFirmowaV1_Sekcja_06,
  StronaFirmowa_V1_Miniatura,
  StronaFirmowa_V1_Realizacja,
  Test_Serwisu_StronaFirmowa_V1
} from "../../../../../assets";
import {
  Benefits,
  Contact,
  DlaczegoMy,
  StopkaSukces
} from "../../../WebsiteOffer/containers";

import ReactGA from "react-ga4";

const GA_MEASUREMENT_ID = "G-KQ3CYHTFBL";

ReactGA.initialize(GA_MEASUREMENT_ID, { debug: true });

const OfertaDATA = [
  {
    title: "Strona Internetowa - Wizytówka Twojej Firmy",
    skladProduktu: [
      `Strona Internetowa - czas realizacji tylko 48h`,
      `Dostosowanie strony do Twoich potrzeb`,
      `Hosting - 12 miesięcy`,
      `Domena z końcówką: ".pl , .com.pl, .net.pl, .org.pl, .biz.pl, .info.pl" - 12 miesięcy`,
      `Certyfikat SSL - 12 miesięcy`,
      `Wsparcie Techniczne - 12 miesięcy`,
      `Konto pocztowe w domenie - 12 miesięcy`,
      `System zarządzania treścią`,
      `Instrukcja obsługi systemu`,
      `Możliwość dodania na stronie do 3 zdjęć Stockowych`,
      `Responsywność (RWD) - Strona dostosowana jest do różnych szerokości ekranów tj. : Komputery Stacjonarne, Laptopy, Tablety, Telefony Komórkowe`,
      `Dowolna ilość poprawek w fazie projektowania`
    ],
    cena: "799",
    cena_wieksza: "1099",
    opis_sekcji: [
      {
        textColor: "",
        background: "#00A9FF",
        title: "Sekcja Header",
        img: StronaFirmowaV1_Sekcja_01,
        sklad_sekcji: [
          `Nawigacja - w nawigacji znajduje się nr telefonu oraz odnośniki do profilów w mediach społecznościowych w wersji na komputery, natomiast dla tabletów i telefonów ikony znajdują się w stopce`,
          `Logo - Logo Twojej firmy`,
          `Menu - odnośniki do poszczególnych sekcji`,
          `Slider - Slider składa się z Tytułu, opisu oraz zdjęcia, możliwość dodania dowolnej ilości Slajdów`
        ]
      },
      {
        textColor: "",
        background: "white",
        title: "Sekcja O Firmie",
        img: StronaFirmowaV1_Sekcja_02,
        sklad_sekcji: [
          `Tytuł Sekcji - "O Firmie", możliwość edycji`,
          `Podtytuł - możliwość edycji`,
          `Opis - Dodaj dowolny opis i przedstaw klientom swoją firmę`,
          `Zdjęcie`
        ]
      },
      {
        textColor: "white",
        background: "#313131",
        title: "Sekcja Oferta",
        img: StronaFirmowaV1_Sekcja_03,
        sklad_sekcji: [
          `Tytuł Sekcji - "Oferta", możliwość edycji`,
          `Podtytuł - możliwość edycji`,
          `Lista usług / produktów - możliwość dodanie dowolnej liczby usług czy produktów`
        ]
      },
      {
        textColor: "",
        background: "white",
        title: "Sekcja Kontakt",
        img: StronaFirmowaV1_Sekcja_04,
        sklad_sekcji: [
          `Tytuł Sekcji - "Kontakt", możliwość edycji`,
          `Podtytuł - możliwość edycji`,
          `Formularz kontaktowy`
        ]
      },
      {
        textColor: "",
        background: "#F5EFE7",
        title: "Stopka",
        img: StronaFirmowaV1_Sekcja_05,
        sklad_sekcji: [
          `Logo`,
          `Nr Telefonu`,
          `Ikony Social Mediów - dla wersji mobilnej`,
          `Dane Firmy`,
          `Adres e-mail`
        ]
      },
      {
        textColor: "",
        background: "white",
        title: "Zaplecze",
        img: StronaFirmowaV1_Sekcja_06,
        sklad_sekcji: [
          `Edycja treści poszczególnych sekcji`,
          `Dodawanie Slajdów do Slidera`,
          `Dodawanie produktów / usług / ofert`,
          `Dodanie danych firmy`
        ]
      },
      {
        textColor: "",
        background: "orange",
        title: "Realizacja",
        img: StronaFirmowa_V1_Realizacja,
        sklad_sekcji: [
          `1. Kontaktujesz się z nami w celu zakupu i omówienia szczegółów dotyczących wyglądu`,
          `2. Przesyłasz nam treści, które zostaną zamieszczone na stronie.`,
          `3. Po otrzymaniu szczegółów oraz treści w ciągu 72h przygotowujemy projekt strony.`,
          `4. Przesyłamy Ci projekt strony.`,
          `5. Wprowadzamy ewentualne poprawki.`,
          `6. Po akceptacji przenosimy stronę na docelowy hosting (klienta lub nasz) oraz łączymy z wybraną domeną.`,
          `7. Oddajemy Ci gotową stronę internetową i przekazujemy dane dostępowe do zaplecza oraz niezbędne instrukcje.`
        ]
      },
      {
        textColor: "",
        background: "white",
        title: "Przetestuj Stronę przed zakupem",
        img: Test_Serwisu_StronaFirmowa_V1,
        sklad_sekcji: [
          `1. Kontaktujesz się z nami w celu zakupu i omówienia szczegółów dotyczących wyglądu`
        ],
        linki: [
          {
            link_front: "https://strona-firmowa.twojamarkawsieci.pl/",
            link_zaplecze:
              "https://strona-firmowa.twojamarkawsieci.pl/zaplecze/"
          }
        ],
        zaplecze: [
          {
            login: `test@twojamarkawsieci.pl`,
            haslo: "#twoja-firmaAdminPanel"
          }
        ]
      }
    ]
  }
];

const StronaFirmowaV1 = () => {
  const zamawiam = useRef(null);
  return (
    <div className="stronaFirmowaV1__kontener">
      <ProductFirstLook
        miniatura={StronaFirmowa_V1_Miniatura}
        title={OfertaDATA[0].title}
        SkladPoroduktu={OfertaDATA[0].skladProduktu}
        cena={OfertaDATA[0].cena}
        cena_wieksza={OfertaDATA[0].cena_wieksza}
        zamawiam={zamawiam}
      />
      <div className="opisProduktu__kontener">
        <div className="opisProduktu__tytul-kontener sekcja-padding">
          <h2>Schemat Produktu</h2>
        </div>
        {OfertaDATA[0].opis_sekcji.map((data, index) => {
          return (
            <div
              className="sekcjaOpisowa__kontener-opisu sekcja-padding"
              key={index + "opis_sekcji"}
              style={{
                backgroundColor: data.background,
                color: data.textColor
              }}>
              <SekcjaOpisowa
                title={data.title}
                img={data.img}
                sklad_sekcji={data.sklad_sekcji}
                linki={data.linki}
                zaplecze={data.zaplecze}
              />
            </div>
          );
        })}
        <div className="strona_firmowa__kontakt-kontener" ref={zamawiam}>
          <SprzedazKontakt cena={OfertaDATA[0].cena} />
        </div>
        <div
          className="strona_firmowa__kontakt-kontener-benefits"
          style={{ backgroundColor: "white" }}>
          <Benefits />
        </div>
        <DlaczegoMy />
        <StopkaSukces />
      </div>
    </div>
  );
};

export default StronaFirmowaV1;
