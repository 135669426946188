import React from "react";
import "./WebsiteOfferModule.css";

const WebsiteOfferModule = ({
  title,
  title_color,
  typ,
  szablon_graficzny,
  sekcje,
  podstrony,
  stock,
  hosting,
  domena,
  zabezpieczenia,
  aktualizacja,
  system_cms,
  wsparcie_techniczne,
  kopia_zapasowa,
  modyfikacje,
  copywritting,
  cena_rok
}) => {
  return (
    <div className="websiteoffermodule__kontener">
      <div
        className="websiteoffermodule__titleSection__kontener"
        style={{ backgroundColor: `${title_color}` }}>
        <h3>{title}</h3>
      </div>
      <div className="websiteoffermodule__opcje__kontener">
        <div className="opcje">
          <p>
            Typ: <span>{typ}</span>
          </p>
        </div>
        <div className="opcje">
          <p>
            Indywidualny Szablon Graficzny: <span>{szablon_graficzny}</span>
          </p>
        </div>
        <div className="opcje">
          <p>
            Liczba Sekcji: <span>{sekcje}</span>
          </p>
        </div>
        <div className="opcje">
          <p>
            Liczba Podstron: <span>{podstrony}</span>
          </p>
        </div>
        <div className="opcje">
          <p>
            Liczba Zdjęć Stockowych: <span>{stock}</span>
          </p>
        </div>
        <div className="opcje">
          <p>
            Hosting: <span>{hosting}</span>
          </p>
        </div>
        <div className="opcje">
          <p>
            Domena: <span>{domena}</span>
          </p>
        </div>

        <div className="opcje">
          <p>
            Zabezpieczenia: <span>{zabezpieczenia}</span>
          </p>
        </div>
        <div className="opcje">
          <p>
            Aktualizacja: <span>{aktualizacja}</span>
          </p>
        </div>
        <div className="opcje">
          <p>
            System CMS: <span>{system_cms}</span>
          </p>
        </div>
        <div className="opcje">
          <p>
            Wsparcie Techniczne: <span>{wsparcie_techniczne}</span>
          </p>
        </div>
        <div className="opcje">
          <p>
            Kopia Zapasowa: <span>{kopia_zapasowa}</span>
          </p>
        </div>
        <div className="opcje">
          <p>
            Ilość modyfikacji na etapie projektowania:{" "}
            <span>{modyfikacje}</span>
          </p>
        </div>
        <div className="opcje">
          <p>
            Copywriting: <span>{copywritting}</span>
          </p>
        </div>
      </div>
      <div className="websiteoffermodule__opcje__kontener_cena">
        <h3>{cena_rok}</h3>
      </div>
    </div>
  );
};

export default WebsiteOfferModule;
