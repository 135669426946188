import React from "react";
import "./ExmapleCardMore.css";
import { IoClose } from "react-icons/io5";
import { BodyText } from "../../../components";
import { urlFor } from "../../../client";

const ExmapleCardMore = ({
  setreadMore,
  id,
  doc,
  imgFull,
  link,
  title,
  img,
  imgUrl_before,
  imgBefore
}) => {
  // console.log(imgUrl_before);
  return (
    <div className="examplecardmore__container sekcja-padding">
      <div className="examplecarmore__head-section">
        <div className="examplecarmore__head-section_title-container">
          <h3>{title}</h3>
        </div>
        <div className="examplecarmore__head-section_button-container">
          <button
            onClick={() => {
              setreadMore(false);
            }}>
            <IoClose />
          </button>
        </div>
      </div>
      <div className="examplecardmore__container-section">
        <div
          className="examplecardreammore__middle-section"
          style={imgBefore ? { flexDirection: "column" } : {}}>
          <div
            className="examplecardreammore__middle-section_img"
            style={
              doc.blockContent
                ? {}
                : { width: "100%" } || imgBefore
                ? { order: 2, width: "100%" }
                : {}
            }>
            {imgBefore && (
              <p className="examplecardreammore__retusz">Po Retuszu</p>
            )}
            {img && <img src={urlFor(img)} alt={title + img} />}
          </div>
          <div
            className="examplecardreammore__middle-section_text"
            style={
              imgBefore ? { width: "100%", marginTop: "2rem", order: 1 } : {}
            }>
            <div className="examplecardreammore__middle-section_text-bodyText">
              {imgBefore && (
                <>
                  <p className="examplecardreammore__retusz">Przed Retuszem</p>
                  <img src={urlFor(imgBefore)} alt={title + img} />
                </>
              )}
              {!imgBefore && <BodyText doc={doc} />}
            </div>
            {link && (
              <div className="examplecardreammore__middle-section_text-link">
                <p>
                  <a href={link} target="_blank">
                    {link.slice(7)}
                  </a>
                </p>
              </div>
            )}
          </div>
        </div>

        {imgFull && (
          <div className="examplecardreammore-fullSizeImg">
            <img src={urlFor(imgFull)} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExmapleCardMore;
