import { client } from "../client";

const mail = [];
const phone = [];

client
  .fetch(`* [_type == "contactData"][0]`)
  .then((data) => {
    mail.push(data.mail);
    phone.push(data.phone);
  })
  .catch(console.error);

// console.log(mail, phone);

export default [
  {
    phone: phone
  },
  {
    mail: mail
  }
];
