import React, { useEffect, useState } from "react";
import "./AboutSkillset.css";
import { client, urlFor } from "../../../client";
import { BodyText } from "../../../components";

const AboutSkillset = () => {
  const [skillset, setskillset] = useState([]);
  const [skill, setskill] = useState([]);

  useEffect(() => {
    client
      .fetch(`*[_type == "skillset"]`)
      .then((data) => setskillset(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    client
      .fetch(`*[_type == "skills"]`)
      .then((data) => setskill(data))
      .catch(console.error);
  }, []);

  return (
    <div className="aboutskillset__container sekcja-padding" id="aboutUs">
      <div className="aboutskillset__text-section">
        <div className="aboutskillset-text-headSection">
          {skillset.map((skill, index) => (
            <>
              <h4
                className="section-category"
                key={index + skill.category + "skillset"}>
                {skill.category}
              </h4>
              <h2
                className="section-title"
                key={index + skill.title + "skillset"}
                style={{ textAlign: "left" }}>
                {skill.title}
              </h2>
              <h2
                className="section-subtitle"
                key={skill.subtitle + "skillset"}
                style={{ textAlign: "left" }}>
                {skill.subtitle}
              </h2>
            </>
          ))}
        </div>
        <div className="aboutskillset-text-paragraph">
          {skillset.map((data, index) => (
            <>
              {/* <p className="section-paragraph" key={data.text + index + "p"}>
                {data.text}
              </p> */}
              <p className="section-paragraph">
                <BodyText doc={data} />
              </p>
            </>
          ))}
        </div>
        <div className="aboutskillset-text-skillset">
          {skill.map((data, index) => {
            return (
              <div
                className="skillset__module-container"
                key={index + data.title + "aboutskillset-text-skillset"}>
                <h5>{data.title}</h5>

                <div className="skillset__module-skill_panel-container">
                  <div className="skillset__module-skill_panel">
                    <div
                      style={{ width: `${data.level}%` }}
                      className="skillset__module-skill_color"></div>
                  </div>
                  <div className="skillset__show_module">
                    <label>{data.level}</label>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="aboutskillset__img-section">
        <div className="aboutskillset__img-section_img-container">
          {skillset.map((data, index) => {
            return (
              <img
                src={data.imgUrl ? urlFor(data.imgUrl) : ""}
                alt={data.title + " image"}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AboutSkillset;
