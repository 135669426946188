import React from "react";
import "./SekcjaOpisowa.css";

const SekcjaOpisowa = ({ title, img, sklad_sekcji, linki, zaplecze }) => {
  return (
    <div className="sekcja__opisowa-kontener">
      <div className="sekcja__opisowa-tekst-kontener ">
        <h3>{title}</h3>
        {title === "Przetestuj Stronę przed zakupem" ? (
          <>
            <p>
              Dajemy możliwość sprawdzenia / przetestowania strony przed
              zakupem!
            </p>
            <div className="sekcja__opisowa-kontener-linki">
              <a href={linki[0].link_front} target="_blank">
                Strona Główna
              </a>
              <a href={linki[0].link_zaplecze} target="_blank">
                Zaplecze
              </a>
            </div>
            <p>Logowanie do zaplecza:</p>
            <p>Login: {zaplecze[0].login}</p>
            <p>Hasło: {zaplecze[0].haslo}</p>
          </>
        ) : (
          <>
            <p>
              {title === "Zaplecze"
                ? "Jakie możliwości ma zaplecze?"
                : `${
                    title === "Realizacja"
                      ? `Jak wygląda realizacja zamówienia?`
                      : `W skład sekcji wchodzą następujące elementy:`
                  } `}
            </p>
            {sklad_sekcji.map((data, index) => {
              return (
                <>
                  <p key={data + index}>&#8226; {data}</p>
                </>
              );
            })}
          </>
        )}
      </div>
      <div className="sekcja__opisowa-img-kontener">
        <img src={img} alt={title} />
      </div>
    </div>
  );
};

export default SekcjaOpisowa;
