import React, { useState } from "react";
import "./ExampleCard.css";
import { urlFor } from "../../../client";
import { ExmapleCardMore } from "../../../components";
import { no_img } from "../../../assets";

const Example = ({
  title,
  imgUrl,
  id,
  setreadMore,
  readMore,
  setid,
  doc,
  setdoc,
  settitle,
  imgUrl_full,
  setimgFull,
  setlink,
  link,
  setimg,
  imgUrl_before,
  setimgBefore
}) => {
  const buttonHandler = () => {
    setdoc(doc);
    setreadMore(true);
    setid(id);
    settitle(title);
    setimgFull(imgUrl_full);
    setlink(link);
    setimg(imgUrl);
    setimgBefore(imgUrl_before);
  };

  return (
    <>
      <div
        className="exampleCard__container"
        role="button"
        onClick={buttonHandler}>
        <div className="exampleCard__img-section">
          <img src={imgUrl ? urlFor(imgUrl) : no_img} alt={title} />
        </div>
        <div className="exampleCard__text-section">
          <h4>{title}</h4>
        </div>
      </div>
    </>
  );
};

export default Example;
