import React, {useEffect,useState} from 'react'
import './RetouchBenefits.css'
import { client, urlFor } from '../../../client';

const RetouchBenefits = () => {
  const [benefit, setbenefit] = useState([]);
  const [headData, setHeadData] = useState([]);
  
  useEffect(
    ()=> {
      
      client.fetch(
        `
      *[_type == "retouch_heads"]{
        retouch_benefits_head
      }`
  
      ).
      then(
        data => setHeadData(data)
      ).
      catch(
        console.error
      )
    },[]
    
  )
  
  useEffect(
    ()=> {
      
      client.fetch(`
      *[_type == "retouch_benefits"]
      `
  
      ).
      then(
        data => setbenefit(data)
      ).
      catch(
        console.error
      )
    },[]
  
   
  )
  

    return (
      <div className='retouchbenefits__container '>
        <div className='retouchbenefits__section sekcja-padding'>
        <div className='retouchbenefits__head-section'>
          
              {
               headData.map(
                (data,index) => {  
                  return (   
               <>
           <h4 className='section-category' key={index+data.retouch_benefits_head.category_name+"kategoria"} > {data.retouch_benefits_head.category_name} </h4>
           <h2 className='section-title' key={index+data.retouch_benefits_head.title+"tytuł"}>{data.retouch_benefits_head.title}</h2>
           <h2 className='section-subtitle'key={index+data.retouch_benefits_head.sub_title+"subtytuł"}>{data.retouch_benefits_head.sub_title}</h2>
               </>
               )
       
                }
             )
            }
  
  
        </div>
        <div className='retouchbenefits__benefitsset-container'>
               
          
             {
              benefit.map(
                (data,index)=> {
                  return (
                    <>
                      <div className='retouchbenefits__benefitsset-module_container' key={index+data.benefit}>
                      <div className='benefitsset-module__img-section'>
                      <img src={data.imgUrl ? urlFor(data.imgUrl) : ""} alt={data.title} />
                      </div>
                      <div className='benefitsset-module__txt-section'>
                      <h4>{data.benefit}</h4>
                      {
                         data.text.map(
                          (text,index) =>
                          {
  
                          return (
                            <>
                            <p key={text.children[index]._key}>{text.children[index].text}</p>
                            </>
                          )
                        } 
                         )
                      }
                
                      </div>
                      </div>
                      
                    </>
                  )
                }
              )
             }
            
  
  
   
  
  
        </div>
        </div>
      </div>
  
  
    )
}

export default RetouchBenefits