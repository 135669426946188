import React from "react";
import "./OfferCard.css";
import { BsCheck } from "react-icons/bs";

const OfferCard = ({
  title,
  buttonPeriodId,
  price_month,
  price_year,
  opis,
  opcja1,
  opcja2,
  opcja3,
  opcja4,
  opcja5,
  opcja6,
  czas_realizacji
}) => {
  const opcje = [];

  opcja1 && opcje.push(opcja1);
  opcja2 && opcje.push(opcja2);
  opcja3 && opcje.push(opcja3);
  opcja4 && opcje.push(opcja4);
  opcja5 && opcje.push(opcja5);
  opcja6 && opcje.push(opcja6);

  return (
    <div className="offerCard__container">
      <div className="offerCard__head-section">
        <h4>{title}</h4>
        <p className="offerCard__price">
          {buttonPeriodId === 1 ? price_month : price_year} PLN
        </p>
        {buttonPeriodId === 1 && (
          <>
            <p
              key={"wybro_okresu_platnosci"}
              className="offerCard__paragraph-month">
              {price_month == "" || price_month == "-" ? "-" : "miesięcznie"}
            </p>
          </>
        )}
      </div>
      <div className="offerCard__middle-section">
        <p>{opis}</p>
      </div>
      <div className="offerCard__opcje-section">
        {opcje.map((data, index) => (
          <>
            <p key={index + "oferta_strony_www"}>
              <span>
                <BsCheck />
              </span>{" "}
              {data}
            </p>
          </>
        ))}
      </div>
      <div className="offercard__footer-section">
        <p>Czas realizacji : {czas_realizacji}</p>
      </div>
    </div>
  );
};

export default OfferCard;
