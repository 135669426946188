export const abonamentStronyWWW = [
  {
    title: "Website S",
    title_color: "#00A9FF",
    typ: "Strona Wizytówka / OnePage / LandingPage",
    szablon_graficzny: "Tak",
    sekcje: "4 - 6",
    podstrony: "Brak",
    stock: "do 4 zdjęć",
    hosting: "12 miesięcy",
    domena: "12 miesięcy",
    zabezpieczenia: "SSL 12 miesięcy",
    aktualizacja: "1 treść / miesięcznie przez 12 miesięcy",
    system_cms: "Brak / Możliwość wdrożenia",
    wsparcie_techniczne: "12 miesięcy",
    kopia_zapasowa: "co 24h",
    modyfikacje: "Dowolna",
    copywritting: "Dodatkowo",
    cena_rok: "1499,99 PLN"
  },
  {
    title: "Website L",
    title_color: "#FF5B22",
    typ: "Strona Wizytówka / OnePage / LandingPage",
    szablon_graficzny: "Tak",
    sekcje: "6 - 10",
    podstrony: "Brak",
    stock: "do 7 zdjęć",
    hosting: "12 miesięcy",
    domena: "12 miesięcy",
    zabezpieczenia: "SSL 12 miesięcy",
    aktualizacja: "1 treść / miesięcznie przez 12 miesięcy",
    system_cms: "Brak / Możliwość wdrożenia",
    wsparcie_techniczne: "12 miesięcy",
    kopia_zapasowa: "co 24h",
    modyfikacje: "Dowolna",
    copywritting: "Dodatkowo",
    cena_rok: "2499,99 PLN"
  },
  {
    title: "Website Individual",
    title_color: "#1A5D1A",
    typ: "Serwis Internetowy",
    szablon_graficzny: "Tak",
    sekcje: "6 - 10",
    podstrony: "3 - 6",
    stock: "od 10 zdjęć",
    hosting: "12 miesięcy",
    domena: "12 miesięcy",
    zabezpieczenia: "SSL 12 miesięcy",
    aktualizacja: "Według Ustaleń",
    system_cms: "Tak",
    wsparcie_techniczne: "12 miesięcy",
    kopia_zapasowa: "co 24h",
    modyfikacje: "Dowolna",
    copywritting: "w pakiecie",
    cena_rok: "od 3499,99 PLN"
  }
];
