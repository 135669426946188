import React, { useEffect, useState } from "react";
import "./Footer.css";

import { client, urlFor } from "../../client";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { MdOutlinePlace } from "react-icons/md";

const Footer = () => {
  const [aboutSection, setaboutSection] = useState([]);
  const [test, settest] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `
       *[_type == "footer"]{
        footer_about_section,
        footer_section_menu {
          footer_section_menu_title,
          "menu": *[_type == "footer_menu"],
        },
        footer_contact_section,
        footer_allrights_section
      }`
      )
      .then((result) => setaboutSection(result))
      .catch(console.error);
  }, []);

  return (
    <>
      <div className="footer__container">
        <div className="footer__section-container sekcja-padding">
          <div className="footer__section-item-container">
            <div className="footer__sections-head">
              {aboutSection.map((data, index) => (
                <>
                  <h4 key={data + index}>
                    {data.footer_about_section.footer_about_title}
                  </h4>
                  <p key={index + "opis"}>
                    {data.footer_about_section.footer_about_opis}
                  </p>
                </>
              ))}
            </div>
          </div>
          <div className="footer__section-item-container">
            <div className="footer__sections-head">
              {aboutSection.map((data, index) => (
                <>
                  <h4 key={data + index}>
                    {data.footer_section_menu.footer_section_menu_title}
                  </h4>
                </>
              ))}
              <div className="footer__list-container">
                {aboutSection.map((data) =>
                  data.footer_section_menu.menu.map((data, index) => (
                    <>
                      <a key={index + data.href} href={data.href}>
                        {data.title}
                      </a>
                    </>
                  ))
                )}
              </div>
            </div>
          </div>
          <div className="footer__section-item-container">
            <div className="footer__sections-head">
              {aboutSection.map((data, index) => (
                <>
                  <h4 key={data + index}>
                    {data.footer_contact_section.footer_contact_head}
                  </h4>
                  <div className="footer__list-container">
                    <p>
                      <span>
                        <AiOutlinePhone />
                      </span>{" "}
                      {data.footer_contact_section.footer_contac_phone}
                    </p>
                    <p>
                      {" "}
                      <span>
                        <AiOutlineMail />
                      </span>
                      {data.footer_contact_section.footer_contac_mail}
                    </p>
                    {data.footer_contact_section.footer_contac_adress && (
                      <p>
                        {" "}
                        <span>
                          <MdOutlinePlace />
                        </span>
                        {data.footer_contact_section.footer_contac_adress}
                      </p>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="footer__container-allrights">
        {aboutSection.map((data, index) => {
          return (
            <>
              <p>
                {data.footer_allrights_section.footer_allrights_company}{" "}
                <span>
                  {data.footer_allrights_section.footer_allrights_year}
                </span>
              </p>
            </>
          );
        })}
      </div>
    </>
  );
};

export default Footer;
