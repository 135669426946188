import React, {useState,useEffect} from 'react'
import './WebistesBenefits.css'
import {client, urlFor} from '../../../client'


const WebistesBenefits = () => {

const [benefit, setbenefit] = useState([]);
const [headData, setHeadData] = useState([]);

useEffect(
  ()=> {
    
    client.fetch(
      `
    *[_type == "websites_heads"]{
      websites_benefits_head
    }`

    ).
    then(
      data => setHeadData(data)
    ).
    catch(
      console.error
    )
  },[]
  
)

useEffect(
  ()=> {
    
    client.fetch(`
    *[_type == "website_benefits"]
    `

    ).
    then(
      data => setbenefit(data)
    ).
    catch(
      console.error
    )
  },[]

 
)

// console.log(benefit)
// console.log(headData)

  return (
    <div className='websitesbenefits__container '>
      <div className='websitesbenefits__section sekcja-padding'>
      <div className='websitesbenefits__head-section'>
        
            {
             headData.map(
              (data,index) => {  
                return (   
             <>
         <h4 className='section-category' key={index+data.websites_benefits_head.category_name+"kategoria"} > {data.websites_benefits_head.category_name} </h4>
         <h2 className='section-title' key={index+data.websites_benefits_head.title+"tytuł"}>{data.websites_benefits_head.title}</h2>
         <h2 className='section-subtitle'key={index+data.websites_benefits_head.sub_title+"subtytuł"}>{data.websites_benefits_head.sub_title}</h2>
             </>
             )
     
              }
           )
          }


      </div>
      <div className='websitesbenefits__benefitsset-container'>
             
        
           {
            benefit.map(
              (data,index)=> {
                return (
                  <>
                    <div className='websitesbenefits__benefitsset-module_container' key={index+data.benefit}>
                    <div className='benefitsset-module__img-section'>
                    <img src={data.imgUrl ? urlFor(data.imgUrl) : ""} alt={data.title} />
                    </div>
                    <div className='benefitsset-module__txt-section'>
                    <h4>{data.benefit}</h4>
                    {
                       data.text.map(
                        (text,index) =>
                        {

                        return (
                          <>
                          <p key={text.children[index]._key}>{text.children[index].text}</p>
                          </>
                        )
                      } 
                       )
                    }
              
                    </div>
                    </div>
                    
                  </>
                )
              }
            )
           }
          


 


      </div>
      </div>
    </div>


  )
}

export default WebistesBenefits

