import React from "react";
import "./SocialMedia.css";
import {
  SocialMediaBenefits,
  SocialMediaExampels,
  SocialMediaOffer
} from "../../containers";

const SocialMedia = ({
  readMore,
  setreadMore,
  setid,
  doc,
  setdoc,
  settitle,
  setimgFull,
  setlink,
  setimg,
  setimgBefore
}) => {
  return (
    <div className="socialMedia_container">
      <SocialMediaBenefits />
      <SocialMediaExampels
        readMore={readMore}
        setreadMore={setreadMore}
        setid={setid}
        doc={doc}
        setdoc={setdoc}
        settitle={settitle}
        setimgFull={setimgFull}
        setlink={setlink}
        setimg={setimg}
        setimgBefore={setimgBefore}
      />
      <SocialMediaOffer />
    </div>
  );
};

export default SocialMedia;
