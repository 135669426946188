import React, { useState, useEffect } from "react";
import "./OfertaWWW.css";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ContactData } from "../../../../libs";
const OfertaWWW = () => {
  const [mailValidationTrue, setmailValidationTrue] = useState(false);
  const [mailValidationFalse, setmailValidationFalse] = useState(false);
  const [mathQuestion, setMathQuestion] = useState(generateMathQuestion());
  const [userAnswer, setUserAnswer] = useState("");
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(1);
  const [checkDomena, setcheckDomena] = useState(false);
  const [cenaPodstawowa, setcenaPodstawowa] = useState("");
  const [cenaPoRabacie, setcenaPoRabacie] = useState("");

  function generateMathQuestion() {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    return `${num1} + ${num2}`;
  }

  const SendEmail = (object, values) => {
    // Mail do ustawienia
    emailjs
      .send("service_g2njj4p", "template_stetkhs", object, "YrILGE4XzgFDp-tKo")
      .then(
        (result) => {
          setmailValidationTrue(true);
          console.log(result.text, "Wiadomość wysłana poprawnie");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    setTimeout(() => {
      mailValidationTrue && setmailValidationTrue(false);
      mailValidationFalse && setmailValidationFalse(false);
    }, 4500);
  }, []);
  return (
    <div className="wywiadwww__container">
      <div className="contact__section-container">
        <div className="formik__wrapper">
          <Formik
            enableReinitialize={true}
            initialValues={{
              subject: `Oferta na wykonanie strony internetowej - twojamarkawsieci.pl`,
              // Dane klineta
              nameAndsurname: "",
              email: "",
              phone: "",
              // Wywiad
              // Informacje o fimie
              companyName: "",

              //Oferta
              abonament: "",
              liczba_podstron: "",
              nazwa_domeny: "",
              poczta: "",

              // Cena
              cena_podstawowa: cenaPodstawowa,
              cena_rabat: Math.ceil(
                ((parseInt(cenaPodstawowa) - parseInt(cenaPoRabacie)) * 100) /
                  parseInt(cenaPodstawowa)
              ),
              cena_po_rabacie: cenaPoRabacie,
              cena_dzienna: Math.ceil(cenaPoRabacie / 365),
              // Kontakt do nas
              contact_phone: ContactData[0].phone,
              contact_mail: ContactData[1].mail
            }}
            validationSchema={Yup.object({
              nameAndsurname: Yup.string()
                .min(4, "Imię i naziwsko musi posiadać minimum 4 znaki")
                .max(30, "Imię i nazwisko musi posiadać maksimum 30 znaków")
                .required("Pole nie może być puste"),

              email: Yup.string()
                .email("Błędny format e-mail")
                .min(4, "E-mail musi posiadać minimum 4 znaki")
                .max(30, "E-mail musi posiadać maksimum 30 znaków")
                .required("Pole nie może być puste"),
              phone: Yup.number()
                .positive()
                .integer()
                // .string()
                // .max(12, "Telefon musi posiadać maksimum 12 znaków")
                .typeError("Pole telefon może zawierać tylko cyfry")
                .min(9, "Telefon musi posiadać minimum 9 znaków")
                .required("Pole nie może być puste")
            })}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                // Sprawdź, czy odpowiedź użytkownika jest poprawna
                if (parseInt(userAnswer) === eval(mathQuestion)) {
                  setIsAnswerCorrect(true);
                  SendEmail(values);

                  actions.setSubmitting(false);
                  // actions.resetForm({
                  //   values: ""
                  // });
                  setUserAnswer("");
                  console.log(values);
                  console.log(cenaPodstawowa, cenaPoRabacie);
                } else {
                  setIsAnswerCorrect(false);
                }
              }, 1000);
            }}>
            <Form className="formik__container formik__sprzedaz">
              <div className="inputs_container ">
                {/* CENA */}
                <div className="inputs_container ">
                  <div className="formik__title-section">
                    <h3>Cena</h3>
                  </div>
                  <div className="formik__sekcja oferta_www_daneklienta">
                    <div className="formik_field-container">
                      <Field
                        type="input"
                        className="formik__field"
                        name="cena_podstawowa"
                        id="cena_podstawowa"
                        value={cenaPodstawowa}
                        onChange={(e) => setcenaPodstawowa(e.target.value)}
                        required
                      />

                      <label className="plaholder__label" htmlFor="">
                        Cena podstawowa
                      </label>
                    </div>
                    <div className="formik_field-container">
                      <Field
                        type="input"
                        className="formik__field"
                        name="cena_po_rabacie"
                        id="cena_po_rabacie"
                        value={cenaPoRabacie}
                        onChange={(e) => setcenaPoRabacie(e.target.value)}
                        required
                      />

                      <label className="plaholder__label" htmlFor="">
                        Cena po rabacie
                      </label>
                    </div>
                  </div>
                </div>

                {/* KONIEC CENA */}
                {/* // Dane klienta  */}
                <div className="formik__title-section">
                  <h3>Dane Klienta</h3>
                </div>
                <div className="formik__sekcja oferta_www_daneklienta">
                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="nameAndsurname"
                      id="nameAndsurname"
                      required
                    />
                    <ErrorMessage
                      name="nameAndsurname"
                      component="label"
                      className="errorMessage__label"
                    />
                    <label className="plaholder__label" htmlFor="">
                      Imię i nazwisko
                    </label>
                  </div>

                  <div className="formik_field-container">
                    <Field
                      className="formik__field"
                      name="email"
                      id="email"
                      required
                    />
                    <ErrorMessage
                      name="email"
                      component="label"
                      className="errorMessage__label"
                    />
                    <label className="plaholder__label" htmlFor="">
                      Adres e-mail
                    </label>
                  </div>

                  <div className="formik_field-container">
                    <Field
                      className="formik__field"
                      name="phone"
                      id="phone"
                      required
                    />
                    <ErrorMessage
                      name="phone"
                      component="label"
                      className="errorMessage__label"
                    />
                    <label className="plaholder__label" htmlFor="">
                      Nr telefonu
                    </label>
                  </div>
                  <div className="formik_field-container">
                    <Field
                      className="formik__field"
                      name="companyName"
                      id="companyName"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      Nazwa Firmy
                    </label>
                  </div>
                </div>
              </div>

              {/* KONIEC DANYCH KLIENTA */}

              {/* SZCZEGOŁY OFERTY */}
              <div className="inputs_container ">
                {/* // Dane klienta  */}
                <div className="formik__title-section">
                  <h3>Szczegóły Oferty</h3>
                </div>
                <div className="formik__sekcja oferta_www_daneklienta">
                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="abonament"
                      id="abonament"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      Abonament
                    </label>
                  </div>

                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="liczba_podstron"
                      id="liczba_podstron"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      Liczba podstron
                    </label>
                  </div>

                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="nazwa_domeny"
                      id="nazwa_domeny"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      Nazwa domeny
                    </label>
                  </div>
                  <div className="formik_field-container">
                    <Field
                      type="input"
                      className="formik__field"
                      name="poczta"
                      id="poczta"
                      required
                    />

                    <label className="plaholder__label" htmlFor="">
                      Skrzynka pocztowa w domenie Tak / Nie / Alias
                    </label>
                  </div>
                </div>
              </div>
              {/* KONIEC SZCZEGÓŁY OFERTY */}

              <div className="formik__question">
                <label htmlFor="mathQuestion" className="formik__field">
                  <p>{mathQuestion} = </p>
                </label>
                <Field
                  type="text"
                  id="mathQuestion"
                  name="mathQuestion"
                  value={userAnswer}
                  onChange={(e) => setUserAnswer(e.target.value)}
                />
              </div>
              {!isAnswerCorrect && (
                <div className="formik__question-error">
                  <p>Nieprawidłowa odpowiedź.</p>
                </div>
              )}
              <div className="formiButton__container">
                <button className="formik_button" type="submit">
                  Wyślij
                </button>
              </div>
            </Form>
          </Formik>
          {mailValidationTrue && (
            <div className="formik__messeageAfterSubmitTrue">
              <p>Wiadomość została wysłana poprawnie</p>
            </div>
          )}
          {mailValidationFalse && (
            <div className="formik__messeageAfterSubmitFalse">
              <p>Wiadomość nie została wysłana, spróbuj ponownie</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OfertaWWW;
