import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import "./AboutUs.css";
import { Logo } from "../../libs";
import { client } from "../../client";
import BodyText from "../BodyText/BodyText";

const AboutUs = ({ setaboutUsActive }) => {
  const [doc, setdoc] = useState([]);
  const [aboutUsTitle, setaboutUsTitle] = useState();

  useEffect(() => {
    client
      .fetch('*[_type == "aboutUs"]')
      .then((data) => {
        setdoc(data[0]);
        setaboutUsTitle(data[0].aboutUs_title);
      })
      .catch(console.error);
  }, []);

  return (
    <div className="aboutUs__container-overlay scale-up-hor-right">
      <div className="aboutUs__container ">
        <div className="aboutUs__head-section">
          <div className="headerContact__nav-section">
            <p>
              <span>{Logo[0].title}</span>
            </p>
            <button onClick={() => setaboutUsActive(false)}>
              <IoClose />
            </button>
          </div>

          <div className="aboutUs__title-section">
            <h3>{aboutUsTitle}</h3>
          </div>
        </div>
        <div className="aboutUs__text-section">
          <p>
            <BodyText doc={doc} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
