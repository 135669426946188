import React from "react";
import Countdown from "react-countdown";
import "./ProductFirstLook.css";

const ProductFirstLook = ({
  miniatura,
  title,
  SkladPoroduktu,
  cena,
  cena_wieksza,
  zamawiam
}) => {
  let kalendarz = new Date("November 26, 2023 23:59:59");
  const hanldeScroll = () => {
    zamawiam.current?.scrollIntoView({ behavior: "smooth" });
  };
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
    } else {
      // Render a countdown
      return (
        <>
          <div className="counter-date">
            <p>Do końca promocji pozostało:</p>
            <p>
              <span>
                {days < 10 ? `0${days}d` : `${days}d`}:
                {hours < 10 ? `0${hours}h` : `${hours}h`}:
                {minutes < 10 ? `0${minutes}m` : `${minutes}m`}:
                {seconds < 10 ? `0${seconds}s` : `${seconds}s`}
              </span>
            </p>
            <p>NIE PRZEGAP OKAZJI!</p>
          </div>
        </>
      );
    }
  };
  // console.log(kalendarz.getTime(), Date.now());
  return (
    <div className="productfirstlook__kontner">
      <div className="productfirstlook__opis-kontener sekcja-padding">
        <div className="productfirstlook__opis-kontener-h1">
          <h1>{title}</h1>
        </div>
        <div className="productfirstlook__opis-kontener-lista">
          <div className="productfirstlook__opis-kontener-lista-h3">
            <h3 key={"Skladproduktu" + 1}>Co wchodzi w skład produktu?</h3>
          </div>
          <div className="productfirstlook__opis-kontener-lista-paragraph">
            {SkladPoroduktu.map((data, index) => {
              return (
                <>
                  <p key={index + data + "Opcja"}>&#8226; {data}</p>
                </>
              );
            })}
          </div>
          <div className="productfirstlook__opis-kontener-cena">
            <p>
              <span>{cena_wieksza}</span> <span>{cena} zł</span>
            </p>
          </div>
          <div className="timer_promocji">
            <p>
              {/* <Countdown date={Date.now() + 11115000} renderer={renderer} /> */}
              <Countdown date={kalendarz.getTime()} renderer={renderer} />
            </p>
          </div>
          <div className="productfirstlook__zmawiam">
            <button onClick={hanldeScroll}>zamawiam</button>
          </div>
        </div>
      </div>
      <div className="productfirstlook__img-kontener">
        <img src={miniatura} alt="miniatura" />
      </div>
    </div>
  );
};

export default ProductFirstLook;
